<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-50" @close="$emit('close')">
			<!--			<div class="fixed inset-0" />-->
			
			<template v-if="dimming">
				<TransitionChild as="template" enter="ease-in-out duration-400" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
					<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
				</TransitionChild>
			</template>
			
			<div class="fixed inset-0 overflow-hidden">
				<div class="absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 flex max-w-full " :class="(direction === 'left') ? 'pr-10 left-0' : 'pl-10 right-0'">
						<TransitionChild as="template" enter="transform transition ease-in-out duration-400 sm:duration-400"
						                 :enter-from="(direction === 'left') ? '-translate-x-full' : 'translate-x-full'"
						                 :leave-to="(direction === 'left') ? '-translate-x-full' : 'translate-x-full'"
						                 leave="transform transition ease-in-out duration-400 sm:duration-400"
						                 leave-from="translate-x-0"
						>
							<DialogPanel class="pointer-events-auto max-w-xl"
							             :class="{
								'max-w-4xl': type === 'product-component-selector' || type === 'product-formulation-selector' || type === 'product-customer-selector',
								'w-64': type === 'leads-filter' || type === 'components-filter' || type === 'products-filter' || type === 'customers-filter' || type === 'projects-filter' || type === 'services-filter' || type === 'formulations-filter' || type === 'counterparties-filter',
								'w-screen': type === 'leads' || type === 'components' || type === 'products' || type === 'customers' || type === 'projects' || type === 'services' || type === 'formulations' || type === 'counterparties' || type === 'product-component-selector' || type === 'product-formulation-selector' || type === 'product-customer-selector'}">
								<div class="flex h-full flex-col divide-y divide-gray-200 shadow-xl" :class="[{ 'bg-gray-50': type === 'leads-filter' || type === 'components-filter' || type === 'products-filter' || type === 'customers-filter' || type === 'projects-filter' }, 'bg-white']">
									<div class="bg-gray-700 py-6 px-4 sm:px-6">
										<div class="flex items-center justify-between">
											<DialogTitle class="text-lg font-medium text-white">
												<template v-if="type === 'leads'">
													{{ exhibitionList[content.exhibition].name }}
												</template>
												<template v-else-if="type === 'components' || type === 'products' || type === 'projects' || type === 'services' || type === 'formulations' || type === 'counterparties'">
													{{ content.title }}
												</template>
												<template v-else-if="type === 'customers'">
													{{ content.contact_name }}
												</template>
												
												<template v-else-if="type === 'product-customer-selector'">
													{{ $t('window_heading_customer_selector') }}
												</template>
												
												<template v-else-if="type === 'product-formulation-selector'">
													{{ $t('window_heading_formulation_selector') }}
												</template>
												
												<template v-else-if="type === 'product-component-selector'">
													{{ $t('window_heading_component_selector') }}
												</template>
												
												<template
													v-else-if="type === 'leads-filter' || type === 'components-filter' || type === 'products-filter' || type === 'customers-filter' || type === 'projects-filter' || type === 'services-filter' || type === 'formulations-filter' || type === 'counterparties-filter'">
													{{ $t('interfaceString__headingFilter') }}
												</template>
											</DialogTitle>
											<div class="ml-3 flex h-7 items-center">
												<button type="button" class="rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="$emit('close')">
													<span class="sr-only">{{ $t('interfaceString__Close') }}</span>
													<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
												</button>
											</div>
										</div>
										<div class="mt-1">
											<template v-if="type === 'leads'">
												<!-- <p class="text-base text-gray-200" v-if="content.country_name">{{ content.country_name }}</p>-->
												<p class="text-sm text-gray-200" v-if="exhibitionList[content.exhibition].dates">{{ exhibitionList[content.exhibition].dates }}</p>
											</template>
											<template v-else-if="type === 'components'">
												<p class="text-sm text-gray-200" v-if="content.category">{{ $t('string_' + content.category) }}</p>
											</template>
											<template v-else-if="type === 'products'">
												<p class="text-sm text-gray-200" v-if="content.SKU">{{ content.SKU }}</p>
											</template>
											<template v-else-if="type === 'customers' || type === 'projects' || type === 'services' || type === 'formulations' || type === 'counterparties'">
												<p class="text-sm text-gray-200">Data placeholder</p>
											</template>
										
										</div>
									</div>
									<div v-if="type === '_product-component-selector'" class="bg-gray-100 py-2 px-1 sm:px-2">
										<div class="relative rounded-md border border-gray-300 _shadow-sm bg-white">
											<MagnifyingGlassIcon class="pointer-events-none absolute top-3 left-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
											<input type="text" v-model.trim="search_query" class="h-11 w-full border-0 bg-transparent pl-11 pr-3 text-gray-900 placeholder:text-gray-300 focus:ring-0 sm:text-sm" autocomplete="no-autocomplete"
											       :placeholder="$t('form_placeholder__search_component_by_title')" role="combobox" aria-expanded="false" aria-controls="options">
										</div>
									</div>
									<div v-if="type === 'product-component-selector'" class="mx-3">
										<table class="min-w-full">
											<thead>
											<tr>
												<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-60">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block w-full text-xs font-medium text-gray-700">{{ $t('form_label__category') }}
																<select v-model="filter.category"
																        class="truncate text-xs block w-full rounded-none rounded-l-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:leading-6">
																	<option :value="null">---</option>
																	<option v-for="(option, optionKey) in parameters.components.category" :key="option" :value="option">{{ $t('string_' + option) }}</option>
																</select>
															</label>
														</div>
														
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'category'}" @click="items.sorting.order_by = 'category'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<th scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block text-xs font-medium text-gray-700 w-full">{{ $t('form_label__title') }}
																<input v-model.trim="filter.title" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__search_component_by_title')" type="text"
																       class="text-xs block w-full rounded-none rounded-l-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
															</label>
														</div>
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'title'}" @click="items.sorting.order_by = 'title'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<th v-if="isFiltered" scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 pt-7 w-4">
													<button @click="resetFilter(); $emit('reset-filter')"
													        class="inline-flex items-center rounded-md border border-red-300 bg-red-50 px-2 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
														<NoSymbolIcon class="h-5 w-5" aria-hidden="true"/>
													</button>
												</th>
											</tr>
											</thead>
										</table>
									</div>
									<div v-if="type === 'product-customer-selector'" class="mx-3">
										<table class="min-w-full">
											<thead>
											<tr>
												<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block text-xs font-medium text-gray-700 w-full">{{ $t('form_label__contact_name') }}
																<input v-model.trim="filter.contact_name" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__search_customer_by_contact_name')" type="text"
																       class="text-xs block w-full rounded-none rounded-l-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
															</label>
														</div>
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'contact_name'}" @click="items.sorting.order_by = 'contact_name'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<th v-if="isFiltered" scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 pt-7 w-4">
													<button @click="resetFilter(); $emit('reset-filter')"
													        class="inline-flex items-center rounded-md border border-red-300 bg-red-50 px-2 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
														<NoSymbolIcon class="h-5 w-5" aria-hidden="true"/>
													</button>
												</th>
											</tr>
											</thead>
										</table>
									</div>
									<div v-if="type === 'product-formulation-selector'" class="mx-3">
										<table class="min-w-full">
											<thead>
											<tr>
												<!-- SKU-->
												<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-40">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block text-xs font-medium text-gray-700 w-full">{{ $t('form_label__SKU') }}
																<input v-model.trim="filter.SKU" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__search_formulation_by_SKU')" type="text"
																       class="text-xs block w-full rounded-none rounded-l-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
															</label>
														</div>
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'SKU'}" @click="items.sorting.order_by = 'SKU'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<!-- category-->
												<th scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-40">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block w-full text-xs font-medium text-gray-700">{{ $t('form_label__category') }}
																<select v-model="filter.category"
																        class="truncate text-xs block w-full rounded-none rounded-l-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:leading-6">
																	<option :value="null">---</option>
																	<option v-for="(option, optionKey) in parameters.formulations.category" :key="option" :value="option">
																		{{ $t('formulation_category_' + option) }}
																	</option>
																</select>
															</label>
														</div>
														
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'category'}" @click="items.sorting.order_by = 'category'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<!-- type-->
												<th scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-40">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block w-full text-xs font-medium text-gray-700">{{ $t('form_label__type') }}
																<select v-model="filter.type"
																        class="truncate text-xs block w-full rounded-none rounded-l-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:leading-6">
																	<option :value="null">---</option>
																	<option v-for="(option, optionKey) in parameters.formulations.type" :key="optionKey" :value="optionKey">
																		{{ $t('formulation_type_' + optionKey) }}
																	</option>
																</select>
															</label>
														</div>
														
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'type'}" @click="items.sorting.order_by = 'type'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<!-- title-->
												<th scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													<div class="flex rounded-md shadow-sm">
														<div class="relative flex flex-grow items-stretch focus-within:z-10">
															<label class="block text-xs font-medium text-gray-700 w-full">{{ $t('form_label__title') }}
																<input v-model.trim="filter.title" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__search_formulation_by_title')" type="text"
																       class="text-xs block w-full rounded-none rounded-l-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
															</label>
														</div>
														<button type="button" class="relative mt-4 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														        :class="{'bg-blue-50':items.sorting.order_by === 'title'}" @click="items.sorting.order_by = 'title'; items.sorting.order_direction = (items.sorting.order_direction === 'DESC') ? 'ASC' : 'DESC'">
															<BarsArrowUpIcon v-if="items.sorting.order_direction === 'DESC'" class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
															<BarsArrowDownIcon v-else class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
														</button>
													</div>
												</th>
												<th v-if="isFiltered" scope="col" class="px-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 pt-7 w-4">
													<button @click="resetFilter(); $emit('reset-filter')"
													        class="inline-flex items-center rounded-md border border-red-300 bg-red-50 px-2 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
														<NoSymbolIcon class="h-5 w-5" aria-hidden="true"/>
													</button>
												</th>
											</tr>
											</thead>
										</table>
									</div>
									
									<div v-if="type === 'leads'">
										<Banner v-if="content.archived_at">
											<ArchiveBoxArrowDownIcon class="h-5 w-5 mr-2" aria-hidden="true" v-if="!dataDeleting"/>
											<span>{{ $t('string_leadIsArchived') }}</span>
										</Banner>
									</div>
									
									<div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
										<div class="relative flex-1 px-4 sm:px-6">
											<DataLoading :loading="inWindowLoading" :fullwidth="false"/>
											<template v-if="!inWindowLoading">
												<template v-if="type === 'product-component-selector'">
													<div class="flow-root -m-6">
														<div class="overflow-x-auto">
															<div class="inline-block min-w-full align-middle">
																<table class="min-w-full divide-y divide-gray-300">
																	<tbody class="divide-y divide-gray-200 bg-white">
																	<template v-if="+components.data.length">
																		<tr v-for="item in components.data" :key="item.id" @click="selectItem(item)" class="cursor-pointer hover:bg-slate-100 odd:bg-slate-50">
																			<td class="whitespace-nowrap px-3 py-4 pl-6 text-sm text-gray-500 w-64">
																				<span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10" v-if="item.category">{{ $t('string_' + item.category) }}</span>
																			</td>
																			<td class="whitespace-nowrap pr-6 py-4 text-sm text-gray-500">{{ item.title }}</td>
																		</tr>
																	</template>
																	<tr v-else>
																		<td v-if="!inWindowLoading" colspan="2" class="px-3 py-4 pl-6 whitespace-nowrap p-5 text-sm font-medium text-gray-900">{{ $t('string_not_found_or_not_match_criteria') }}</td>
																	</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</template>
												
												<template v-if="type === 'product-customer-selector'">
													<div class="flow-root -m-6">
														<div class="overflow-x-auto">
															<div class="inline-block min-w-full align-middle">
																<table class="min-w-full divide-y divide-gray-300">
																	<tbody class="divide-y divide-gray-200 bg-white">
																	<template v-if="+customers.data.length">
																		<tr v-for="item in customers.data" :key="item.id" @click="selectItem(item)" class="cursor-pointer hover:bg-slate-100 odd:bg-slate-50">
																			<td class="whitespace-nowrap pr-6 pl-6 py-4 text-sm text-gray-500">{{ item.contact_name }}</td>
																		</tr>
																	</template>
																	<tr v-else>
																		<td v-if="!inWindowLoading" colspan="2" class="px-3 py-4 pl-6 whitespace-nowrap p-5 text-sm font-medium text-gray-900">{{ $t('string_not_found_or_not_match_criteria') }}</td>
																	</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</template>
												
												<template v-if="type === 'product-formulation-selector'">
													<div class="flow-root -m-6">
														<div class="overflow-x-auto">
															<div class="inline-block min-w-full align-middle">
																<table class="min-w-full divide-y divide-gray-300">
																	<tbody class="divide-y divide-gray-200 bg-white">
																	<template v-if="+formulations.data.length">
																		<tr v-for="item in formulations.data" :key="item.id" class="cursor-pointer hover:bg-slate-100 odd:bg-slate-50">
																			<td class="whitespace-nowrap px-3 py-4 pl-6 text-sm text-gray-500 w-40">{{ item.SKU }}</td>
																			<td class="whitespace-nowrap pl-3 py-4 text-sm text-gray-500 w-40">
																				<span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10" v-if="item.category">
																				{{ $t('formulation_category_' + item.category) }}
																				</span>
																			</td>
																			<td class="whitespace-nowrap pl-3 py-4 text-sm text-gray-500 w-40">
																				<template v-if="item.type">
																					<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-bold text-gray-800 ring-1 ring-inset ring-gray-500/10" :class="item.type+'_type'">{{ $t('formulation_type_' + item.type) }}</span>
																				</template>
																			</td>
																			<td class="whitespace-nowrap pl-3 pr-6 py-4 text-sm text-gray-500">
																				<div class="font-medium text-base" @click="selectItem(item)">{{ item.title }}</div>
																				<span v-if="item.subtype" class="mt-1 inline-flex items-center text-xs text-gray-600">{{ $t('formulation_type_' + item.subtype) }}</span>
																				
																				<div v-if="+item.versions.length">
																					<strong class="mt-2 block">VERSIONS:</strong>
																					<ul>
																						<li v-for="version in item.versions" @click="selectItem(version)" class="cursor-pointer">
																							{{version.SKU}} - {{version.title}}
																						</li>
																					</ul>
																				</div>
																			</td>
																		</tr>
																	</template>
																	<tr v-else>
																		<td v-if="!inWindowLoading" colspan="2" class="px-3 py-4 pl-6 whitespace-nowrap p-5 text-sm font-medium text-gray-900">{{ $t('string_not_found_or_not_match_criteria') }}</td>
																	</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</template>
												
												
												<Lead v-if="type === 'leads'" :content="content" :files="content.files"/>
												<Component v-else-if="type === 'components'" :content="content" :files="content.files"/>
												<Product v-else-if="type === 'products'" :content="content" :files="content.files"/>
												<Customer v-else-if="type === 'customers'" :content="content" :files="content.files"/>
												<Project v-else-if="type === 'projects'" :content="content" :files="content.files"/>
												<Service v-else-if="type === 'projects'" :content="content" :files="content.files"/>
												<Counterparty v-else-if="type === 'counterparties'" :content="content" :files="content.files"/>
												
												<LeadsFilter v-else-if="type === 'leads-filter'" :filter="content"/>
												<ComponentsFilter v-else-if="type === 'components-filter'" :filter="content"/>
												<ProductsFilter v-else-if="type === 'products-filter'" :filter="content"/>
												<CustomersFilter v-else-if="type === 'customers-filter'" :filter="content"/>
												<ProjectsFilter v-else-if="type === 'projects-filter'" :filter="content"/>
												<ServicesFilter v-else-if="type === 'services-filter'" :filter="content"/>
												<FormulationsFilter v-else-if="type === 'formulations-filter'" :filter="content"/>
												<CounterpartiesFilter v-else-if="type === 'counterparties-filter'" :filter="content"/>
											</template>
										</div>
									</div>
									<div class="flex flex-shrink-0 justify-between px-6 py-4 bg-gray-100">
										<div class="space-x-3 flex">
											<template v-if="type === 'leads' || type === 'components' || type === 'products' || type === 'customers' || type === 'projects' || type === 'services' || type === 'formulations' || type === 'counterparties'">
												<template v-if="managers[content.created_by]">
													<div class="group block flex-shrink-0">
														<div class="flex items-center">
															<div>
																<img class="inline-block h-9 w-9 rounded-full" :src="managers[content.created_by].image" :alt="managers[content.created_by].given_name +' '+ managers[content.created_by].family_name"/>
															</div>
															<div class="ml-3">
																<p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{ managers[content.created_by].given_name }} {{ managers[content.created_by].family_name }}</p>
																<p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{ formatDate(content.created_at) }}</p>
															</div>
														</div>
													</div>
												</template>
											</template>
											
											<template
												v-else-if="type === 'leads-filter' || type === 'components-filter' || type === 'products-filter' || type === 'customers-filter' || type === 'projects-filter' || type === 'services-filter' || type === 'formulations-filter' || type === 'counterparties-filter'">
												<button @click="resetFilter(); $emit('reset-filter')"
												        class="inline-flex items-center rounded-md border border-red-300 bg-red-50 px-3 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<NoSymbolIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interface_string__reset_filter') }}</span>
												</button>
											</template>
											
											<template v-else-if="type === 'product-component-selector'">
												<div class="relative flex items-start">
													<div class="flex h-6 items-center">
														<input id="do_not_close_window_on_select" aria-describedby="comments-description" v-model="do_not_close_window_on_select" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"/>
													</div>
													<div class="ml-3 text-sm leading-6">
														<label for="do_not_close_window_on_select" class="text-gray-900">{{ $t('do_not_close_window_on_select') }}</label>
													</div>
												</div>
											</template>
										</div>
										<div class="space-x-3">
											<template v-if="type === 'leads'">
												<router-link :to="'/lead/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/lead/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'components'">
												<router-link :to="'/component/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/component/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'products'">
												<router-link :to="'/product/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/product/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'customers'">
												<router-link :to="'/customer/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/customer/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'projects'">
												<router-link :to="'/project/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/project/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'services'">
												<router-link :to="'/service/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/service/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'formulations'">
												<router-link :to="'/formulation/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/formulation/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											<template v-else-if="type === 'counterparties'">
												<router-link :to="'/counterparty/'+content.id" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<ArrowsPointingOutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Expand') }}</span>
												</router-link>
												<router-link :to="'/counterparty/'+content.id+'/edit'" @click="$emit('close')"
												             class="inline-flex items-center rounded-md border border-transparent bg-gray-600 py-2 px-3 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
													<PencilSquareIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
													<span>{{ $t('interfaceString__Edit') }}</span>
												</router-link>
											</template>
											
											<template v-else-if="type === 'product-customer-selector' || type === 'product-formulation-selector' || type === 'product-component-selector'">
												&nbsp;
											</template>
										</div>
									</div>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {BarsArrowUpIcon, BarsArrowDownIcon, ChevronDownIcon, XMarkIcon, PaperClipIcon, PencilSquareIcon, ArrowUpLeftIcon, ArrowsPointingOutIcon, NoSymbolIcon, ArchiveBoxArrowDownIcon, ArrowLongDownIcon, ArrowLongUpIcon} from '@heroicons/vue/24/outline'
import {mapState} from "vuex"

import _ from "lodash";

import Lead from "@/components/elements/leads/Lead"
import LeadsFilter from "@/components/elements/leads/LeadsFilter"

import Component from "@/components/elements/components/Component"
import ComponentsFilter from "@/components/elements/components/ComponentsFilter"

import Customer from "@/components/elements/customers/Customer"
import CustomersFilter from "@/components/elements/customers/CustomersFilter"

import Product from "@/components/elements/products/Product"
import ProductsFilter from "@/components/elements/products/ProductsFilter"

import Project from "@/components/elements/projects/Project"
import ProjectsFilter from "@/components/elements/projects/ProjectsFilter"

import Service from "@/components/elements/services/Service"
import ServicesFilter from "@/components/elements/services/ServicesFilter"

import Counterparty from "@/components/elements/counterparties/Counterparty"
import CounterpartiesFilter from "@/components/elements/counterparties/CounterpartiesFilter"

import Formulation from "@/components/elements/formulations/Formulation"
import FormulationsFilter from "@/components/elements/formulations/FormulationsFilter"

import Banner from "@/components/interface/Banner"
import DataLoading from "@/components/helpers/DataLoading"
import {MagnifyingGlassIcon} from "@heroicons/vue/20/solid";


export default {
	name: "SlideOver",
	mixins: [],
	watch: {
		'open': function (newVal, oldVal) {
			if (newVal) {
				this.fetchData()
				console.log('open: ' + this.type)
			}
		},
		'items.sorting.order_by': function (newVal, oldVal) {
			this.fetchData()
		},
		'items.sorting.order_direction': function (newVal, oldVal) {
			this.fetchData()
		},
		'filter': {
			deep: true,
			handler: function (val, oldVal) {
				this.fetchData()
			}
		}
	},
	components: {
		ArrowLongUpIcon, ArrowLongDownIcon,
		MagnifyingGlassIcon,
		DataLoading,
		
		Customer,
		CustomersFilter,
		
		LeadsFilter,
		Lead,
		
		Component,
		ComponentsFilter,
		
		Product,
		ProductsFilter,
		
		Project,
		ProjectsFilter,
		
		Service,
		ServicesFilter,
		
		Counterparty,
		CounterpartiesFilter,
		
		Formulation,
		FormulationsFilter,
		
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Banner,
		BarsArrowUpIcon, BarsArrowDownIcon, ChevronDownIcon, XMarkIcon, PaperClipIcon, PencilSquareIcon, ArrowUpLeftIcon, ArrowsPointingOutIcon, NoSymbolIcon, ArchiveBoxArrowDownIcon
	},
	props: {
		open: Boolean,
		type: String,
		dimming: {
			type: Boolean,
			default: true
		},
		direction: {
			type: String,
			default: 'right'
		},
		content: Object
	},
	data: function () {
		return {
			search_query: '',
			do_not_close_window_on_select: false
		}
	},
	methods: {
		resetFilter() {
			if (this.type === 'product-component-selector' || this.type === 'product-formulation-selector' || this.type === 'product-customer-selector') Object.keys(this.filter).forEach((i) => this.filter[i] = null)
			else Object.keys(this.content).forEach((i) => this.content[i] = null)
		},
		fetchData() {
			if (this.type === 'product-component-selector') return this.fetchComponentsSelector()
			else if (this.type === 'product-formulation-selector') return this.fetchFormulationsSelector()
			else if (this.type === 'product-customer-selector') return this.fetchCustomersSelector()
			else return null
		},
		selectItem(item) {
			this.$emit("item-selected", item, this.type);
			console.log(this.type)
			if (this.type === 'product-component-selector') {
				item.params = {}
				item.params.quantity = 1
				this.product.data.components.push(item)
			} else if (this.type === 'product-formulation-selector') {
				this.product.data.formulation_id = item.id
				this.product.data.formulation = item
			} else if (this.type === 'product-customer-selector') {
				this.product.data.customer_id = item.id
				this.product.data.customer = item
			}
			
			if (!this.do_not_close_window_on_select) this.$emit('close');
		},
	},
	computed: {
		...mapState(['localDataLoading', 'inWindowLoading', 'exhibitionList', 'countriesData', 'customerBusinessTypesData', 'managers', 'components', 'product']),
		filter: function () {
			if (this.type === 'product-component-selector') return this.components.filter
			else if (this.type === 'product-formulation-selector') return this.formulations.filter
			else if (this.type === 'product-customer-selector') return this.customers.filter
			else return {}
		},
		items: function () {
			if (this.type === 'product-component-selector') return this.components
			else if (this.type === 'product-formulation-selector') return this.formulations
			else if (this.type === 'product-customer-selector') return this.customers
			else return {}
		},
		isFiltered: function () {
			return Boolean(Object.keys(this.filter).find(a => this.filter[a] !== null && this.filter[a] !== ''))
		},
	},
	created() {
		console.log('SlideOver created()')
	},
	mounted() {
		console.log('SlideOver mounted()')
	}
	
}
</script>
