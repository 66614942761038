export default {
	name: "TranslateMixin",
	data: function () {
		return {
			translation: {
				en: {
					menu_dashboard: 'System dashboard',
					menu_leads: 'Leads',
					menu_customers: 'Customers',
					menu_projects: 'Projects',
					menu_components: 'Components',
					menu_formulations: 'Formulations',
					menu_products: 'Products',
					menu_production: 'Production',
					menu_warehouse: 'Warehouse',

					menu_tasks: 'Tasks',
					menu_invoices: 'Invoices',
					menu_files: 'Files',
					menu_reports: 'Reports',
					menu_settings: 'Settings',

					menu_services: 'Services',
					menu_counterparties: 'Counterparties',

					formulation_category_ZH: 'Zurich - Futuristic',
					formulation_category_GV: 'Geneva - Clinical',
					formulation_category_DV: 'Davos - Natural',
					formulation_category_SM: 'St. Moritz - Luxury',
					formulation_category_LG: 'Lugano - Foodie',
					formulation_category_ZR: 'Zermatt - Haircare',
					formulation_category_EVENSWISS: 'EVENSWISS',

					form_label__type: 'Type',
					form_label__subtype: 'Subtype',

					th_price_bulk: 'Price',
					th_customer_id: 'Customer',

					formulation_type_skin_care: 'Skin care',
					formulation_type_serums: 'Serums',
					formulation_type_exfoliators: 'Exfoliators',
					formulation_type_hydrating_and_anti_ageing_creams: 'Hydrating & Anti-ageing creams',
					formulation_type_toners: 'Toners',
					formulation_type_eye_creams: 'Eye creams',
					formulation_type_moisturizers: 'Moisturizers',
					formulation_type_cleansing_lotions: 'Cleansing lotions',
					formulation_type_facial_masks: 'Facial masks',
					formulation_type_shaving_creams: 'Shaving creams',

					formulation_type_body_care: 'Body care',
					formulation_type_soaps: 'Soaps',
					formulation_type_antiperspirants: 'Antiperspirants',
					formulation_type_body_washes: 'Body washes',
					formulation_type_shower_gels: 'Shower gels',
					formulation_type_body_lotions: 'Body lotions',
					formulation_type_scrubs: 'Scrubs',
					formulation_type_oils: 'Oils',

					formulation_type_hair_care: 'Hair care',
					formulation_type_lotions: 'Lotions',
					formulation_type_hair_colorants: 'Hair colorants',
					formulation_type_anti_dandruff_shampoos: 'Anti-dandruff shampoos',
					formulation_type_mousses: 'Mousses',
					formulation_type_texturisers: 'Texturisers',
					formulation_type_hair_care_serums: 'Hair serums',
					formulation_type_conditioners: 'Conditioners',
					formulation_type_shampoos: 'Shampoos',

					formulation_type_sun_care: 'Sun care',
					formulation_type_sun_care_creams: 'Creams',
					formulation_type_sprays: 'Sprays',
					formulation_type_gels: 'Gels',
					formulation_type_sun_care_oils: 'Oils',
					formulation_type_sun_care_lotions: 'Lotions',

					formulation_type_oral_care: 'Oral care',
					formulation_type_toothpastes: 'Toothpastes',
					formulation_type_mouthwashes: 'Mouthwashes',
					formulation_type_flosses: 'Flosses',

					formulation_type_decorative_cosmetics: 'Decorative cosmetics',
					formulation_type_nail_varnishes: 'Nail varnishes',
					formulation_type_blushes: 'Blushes',
					formulation_type_mascaras: 'Mascaras',
					formulation_type_eye_shadows: 'Eye shadows',
					formulation_type_powders: 'Powders',
					formulation_type_foundations: 'Foundations',
					formulation_type_lipsticks_and_glosses: 'Lipsticks & glosses',
					formulation_type_lip_and_eyeliners: 'Lip & eyeliners',

					formulation_type_perfumes: 'Perfumes',
					formulation_type_scented_oils: 'Scented oils',
					formulation_type_after_shave: 'After shave',
					formulation_type_perfumes_perfumes: 'Perfumes',
					formulation_type_salves: 'Salves',

					th_type: 'Type',


					service_category_production: 'Production',
					service_category_service: 'Service',
					service_category_delivery: 'Delivery',
					service_category_other: 'Other',


					global_search_title: 'Global search throughout the system',
					global_search_description: 'Get quick access to system data with global search. Find what you need in seconds.',

					no_results_found_title: 'No results found',
					no_results_found_description: 'No results were found for your search query, try rephrasing your query...',

					modalHeader__App_update_available: 'App update available',
					interfaceString__App_update_available: 'You need to download the updated app version to continue working with the system.',
					modalButton__Update: 'Get newest version',

					Label_Search_Field: 'Search field',
					Placeholder_Search_Field: 'Start typing to search the system',
					Button_Logout: 'Logout',

					InterfaceString__loadingData: 'Loading data',

					nextPage: 'Next page',
					previousPage: 'Previous page',

					interfaceString__Close: 'Close',

					interfaceString__AddNewItem: 'Add new',

					interfaceString__NewLead: 'New lead',
					interfaceString__AddNewLead: 'Add new lead',

					interfaceString__Edit: 'Edit',
					interfaceString__Expand: 'Expand',

					interfaceString__Delete: 'Delete',
					interfaceString__Save: 'Save',
					interfaceString__Create: 'Create',
					interfaceString__Saving: 'Saving',
					interfaceString__Deleting: 'Deleting',

					th_id: '#',
					th_priority: '!!!',

					th_follow_up: 'F',

					th_UCLS: 'U',
					th_EVENSWISS: 'E',
					th_ALOVE: 'A',
					th_SUISSETTE: 'S',

					th_SKU: 'SKU',

					th_exhibition: 'Exhibition',
					th_company_name: 'Company name',
					th_given_name: 'First name',
					th_family_name: 'Last name',
					th_email: 'Email address',
					th_created_by: 'Created by',
					th_created_at: 'Created at',

					th_title: 'Title',
					th_category: 'Category',

					th_contact_name: 'Contact name',

					heading_Leads: 'Leads',
					description_Leads: 'This module is intended for viewing and correcting the data received from the application for collecting data on visitors to the exhibition (UT FORMS), the data must be checked, completed and, if necessary, transferred to the "Clients" module.',
					modal_header_lead: 'Lead',

					pagination_Showing: 'Showing records from',
					pagination_to: 'to',
					pagination_of: 'from',
					pagination_results: 'total',



					//===
					formLabel__interestUCLS: 'UCLS',
					formHelpText__interestUCLS: 'Private label production',

					formLabel__interestEVENSWISS: 'EVENSWISS',
					formHelpText__interestEVENSWISS: 'Products distribution',

					formLabel__interestALOVE: 'ALÔVE',
					formHelpText__interestALOVE: 'Products distribution',

					formLabel__interestSUISSETTE: 'SUISSETTE',
					formHelpText__interestSUISSETTE: 'Products distribution',

					formLabel__companyNameInput: 'Company name',
					formPlaceholder__companyNameInput: 'Enter company name',

					string_FullName: 'Full Name',

					formLabel__customerFamilyNameInput: 'Last name',
					formPlaceholder__customerFamilyNameInput: 'Enter last name',

					formLabel__customerGivenNameInput: 'First name',
					formPlaceholder__customerGivenNameInput: 'Enter first name',

					formLabel__customerCountrySelect: 'From what country',

					formLabel__emailInput: 'Email address',
					formPlaceholder__emailInput: 'contact@domain.com',

					formLabel__websiteInput: 'Website',
					formPlaceholder__websiteInput: 'domain.com',

					formLabel__countryOfInterest: 'Countries of interest',
					formPlaceholder__countryOfInterest: 'Start typing country name',
					formHelpText__countryOfInterest: 'Countries selected:',

					formLabel__businessType: 'Business types',

					formLabel__Notes:'Notes',
					formPlaceholder__Notes: 'Add notes to make it easier to remember what the conversation was about',

					string_InterestedIn: 'Interested in',

					interfaceString__distributor: 'Distributor',
					interfaceString__retailer_department_store: 'Retailer or department store',
					interfaceString__online_platform: 'Online platform',
					interfaceString__pharma_clinics_dermatologist: 'Pharma chain, clinic or dermatologist',
					interfaceString__beauticians: 'Beautician',

					formLabel__otherBusinessType: 'Other type of business',
					formPlaceholder__otherBusinessType: 'You can type in any missing type',

					interfaceString__1_image: 'Image',
					interfaceString__2_image: 'Images',
					interfaceString__5_image: 'Images',





					notificationDescription__syncDataCompleted:'Data successfully synchronized',
					notificationDescription__syncDataError: 'Data synchronization error',

					notificationDescription__sentDataToServer: 'Data saved in the cloud',
					notificationDescription__saveDataLocally: 'Data saved on the device',
					notificationDescription__deleteStagedForm: 'Item deleted successfully',

					LEAD: 'LEAD',
					newLead: 'New lead',

					formLabel__FilesAttached: 'Attached files',

					stringBadRouting: 'Bad routing ...',
					stringLeadNotFound: 'Lead not found',
					stringComponentNotFound: 'Component not found',





					formLabel__Priority: 'Priority',
					interfaceString__leadFromExhibition: 'From what exhibition',

					formHeading__InterestedIn: 'Interested in',
					formDescription__InterestedIn: 'Check the checkboxes for projects in which the client is interested',

					formHeading__ContactInformation: 'General information',
					formDescription__ContactInformation: 'General and contact information',

					formLabel__contactData: 'Contact data',

					formHeading__AdditionalInformation: 'Additional information',
					formDescription__AdditionalInformation: 'Additional information important for the EVENSWISS project',

					formHeading__Notes: 'Notes',
					formDescription__Notes: 'Add notes to make it easier to remember what the conversation was about',

					formHeading__Files: 'Attached files',
					formDescription__Files: 'You can attach photos of business cards, brochures or booklets...',

					formLabel__dropFilesHere: 'Drop files here',

					formLabel__uploadAFile:'Upload a file',
					string__orDragAndDrop:'or Drag and Drop',

					string__filesFormatsDescription:'PNG, JPG, GIF up to 10MB',

					interfaceString__Click_to_remove: 'Click to remove',

					priority_none: 'No priority',
					priority_lowest: 'Lowest priority',
					priority_low: 'Low priority',
					priority_normal: 'Normal priority',
					priority_medium: 'Medium priority',
					priority_high: 'High priority',
					priority_highest: 'Highest priority',

					string_Priority: 'Priority',

					string_leadDeleteHeading: 'Delete Lead?',
					string_leadDeleteDescription: 'Are you sure you want to delete the lead and all connected data?',
					string_leadDeleteButtonConfirm: 'YES - Delete',
					string_leadDeleteButtonCancel: 'NO - Cancel',

					formHeading__followingTasks: 'Following tasks',
					formLabel__dataChecked: 'Data checked',
					formLabel__followUpSent: 'Follow-up email sent',

					notificationHeader__error: 'Error',
					notificationHeader__warning: 'Warning',
					notificationHeader__success: 'Success',
					notificationHeader__info: 'Information',

					notificationDescription__leadSavedSuccessfully: 'Lead successfully saved',
					notificationDescription__leadNotSaved: 'Lead not saved',
					notificationDescription__leadDeletedSuccessfully: 'Lead successfully deleted',
					notificationDescription__leadNotDeleted: 'Lead not deleted',

					notificationDescription__componentSavedSuccessfully: 'Component successfully saved',
					notificationDescription__componentNotSaved: 'Component not saved',
					notificationDescription__componentDeletedSuccessfully: 'Component successfully deleted',
					notificationDescription__componentNotDeleted: 'Component not deleted',

					interfaceString__headingFilter: 'List filtering',
					interfaceString__openFilter: 'Open filter',
					interfaceString__dataFiltered: 'Data filtered',
					interface_string__reset_filter: 'Reset filter',

					interfaceString__exportToExcel: 'Export to XLS',

					formLabel__createdBy: 'Created by',

					string_not_found_or_not_match_criteria: 'Nothing found or doesn\'t match the filter criteria',

					interfaceString__Archive: 'Archive',

					notificationDescription__leadArchivedSuccessfully: 'Lead successfully archived',
					notificationDescription__leadNotArchived: 'Lead archiving error',
					string_leadIsArchived: 'Lead is archived',

					formLabel__withArchived: 'With archived',

					form_label__category: 'Category',
					formLabel__titleInput: 'Title',
					formLabel__descriptionInput: 'Description',
					formLabel__description: 'Description',

					formHeading__ComponentInformation: 'Component data',
					formDescription__ComponentInformation: 'General component data',
					formLabel__general_info: 'General information',

					form_placeholder__titleInput: 'Title',
					formPlaceholder__descriptionInput: 'Description',

					formLabel__id: 'ID number',
					form_label__title: 'Title',

					form_label__contact_name: 'Contact name',
					form_placeholder__contact_name: 'Contact name',

					form_label__SKU: 'SKU',
					form_placeholder__SKU: 'SKU',

					form_placeholder__id: 'ID number',
					form_placeholder__title: 'Title',

					newComponent: 'New component',

					string_componentDeleteHeading: 'Delete Component?',
					string_componentDeleteDescription: 'Are you sure you want to delete the component and all connected data?',
					string_componentDeleteButtonConfirm: 'YES - Delete',
					string_componentDeleteButtonCancel: 'NO - Cancel',

					string_ingredient: 'Ingredient',
					string_bulk: 'BULK',
					string_cap: 'Cap',
					string_clip: 'Clip',
					string_deckel: 'Deckel',
					string_dropper: 'Dropper',
					string_foil: 'Foil',
					string_inliner: 'Inliner',
					string_leaflet: 'Leaflet',
					string_lid: 'Lid',
					string_outer: 'Outer',
					string_overcap: 'Overcap',
					string_pipette: 'Pipette',
					string_printed_primary: 'Printed primary',
					string_pump: 'Pump',
					string_reducer: 'Reducer',
					string_secondary_packaging: 'Secondary packaging',
					string_splashguard: 'Splashguard',
					string_unprinted_primary: 'Unprinted primary',
					string_wiper: 'Wiper',

					string_other: 'Other',



					found_in_leads: 'Found in Leads',
					found_in_customers: 'Found in Customers',
					found_in_projects: 'Found in Projects',
					found_in_components: 'Found in Components',
					found_in_products: 'Found in Products',
					found_in_formulations: 'Found in Formulations',
					found_in_production: 'Found in Production',
					found_in_tasks: 'Found in Tasks',
					found_in_invoices: 'Found in Invoices',
					found_in_files: 'Found in Files',
					found_in_services: 'Found in services',

					window_heading_component_selector: 'Select component',
					window_heading_formulation_selector: 'Select formulation',
					window_heading_customer_selector: 'Select customer',

					form_placeholder__search_component_by_title: 'Search by title',

					do_not_close_window_on_select: 'Do not close window on select',

					newCounterparty: 'New counterparty',

				},
				ru: {
					menu_dashboard: 'Панель инструментов',
					menu_leads: 'Лиды',
					menu_customers: 'Клиенты',
					menu_projects: 'Проекты',
					menu_formulations: 'Формулы',
					menu_components: 'Компоненты',
					menu_products: 'Товары',
					menu_production: 'Производство',
					menu_warehouse: 'Склад',

					menu_tasks: 'Задачи',
					menu_invoices: 'Счета',
					menu_files: 'Файлы',
					menu_reports: 'Отчёты',
					menu_settings: 'Настройки',

					menu_services: 'Сервисы',
					menu_counterparties: 'Контрагенты',

					modalHeader__App_update_available: 'Доступно обновление приложения',
					interfaceString__App_update_available: 'Вам необходимо скачать обновленную версию программы, чтобы продолжить работу с системой.',
					modalButton__Update: 'Скачать новую версию',

					Label_Search_Field: 'Строка поиска',
					Placeholder_Search_Field: 'Начните печатать для поиска в системе',
					Button_Logout: 'Выйти',

					InterfaceString__loadingData: 'Данные загружаются',

					nextPage: 'Следующая страница',
					previousPage: 'Предыдущая страница',

					interfaceString__Close: 'Закрыть',

					interfaceString__AddNewItem: 'Добавить элемент',

					interfaceString__NewLead: 'Новый лид',
					interfaceString__AddNewLead: 'Добавить новый лид',

					interfaceString__Edit: 'Редактировать',
					interfaceString__Expand: 'Развернуть',

					interfaceString__Delete: 'Удалить',
					interfaceString__Save: 'Сохранить',
					interfaceString__Create: 'Создать',
					interfaceString__Saving: 'Сохраняется',
					interfaceString__Deleting: 'Удаляется',

					th_id: '#',
					th_priority: '!!!',

					th_follow_up: 'F',

					th_UCLS: 'U',
					th_EVENSWISS: 'E',
					th_ALOVE: 'A',
					th_SUISSETTE: 'S',

					th_SKU: 'АРТ',

					th_exhibition: 'Выставка',
					th_company_name: 'Название компании',
					th_given_name: 'Имя',
					th_family_name: 'Фамилия',
					th_email: 'Email',
					th_created_by: 'Кем создано',
					th_created_at: 'Когда создано',

					th_title: 'Название',
					th_category: 'Категория',

					th_contact_name: 'Имя контакта',

					heading_Leads: 'Лиды',
					description_Leads: 'Данный модуль предназначен для просмотра и корректировки данных, полученных из приложения для сбора данных о посетителях выставки (UT FORMS), данные должны быть проверены, дополнены и при необходимости перемещены в модуль «Клиенты».',
					modal_header_lead: 'Лид',

					pagination_Showing: 'Показаны записи с',
					pagination_to: 'по',
					pagination_of: 'из',
					pagination_results: 'итого',


					//===
					formLabel__interestUCLS: 'UCLS',
					formHelpText__interestUCLS: 'Производство под собственной торговой маркой',

					formLabel__interestEVENSWISS: 'EVENSWISS',
					formHelpText__interestEVENSWISS: 'Распространение продукции бренда',

					formLabel__interestALOVE: 'ALÔVE',
					formHelpText__interestALOVE: 'Распространение продукции бренда',

					formLabel__interestSUISSETTE: 'SUISSETTE',
					formHelpText__interestSUISSETTE: 'Распространение продукции бренда',

					formLabel__companyNameInput: 'Название компании',
					formPlaceholder__companyNameInput: 'Введите название компании',

					string_FullName: 'Имя Фамилия',

					formLabel__customerFamilyNameInput: 'Фамилия',
					formPlaceholder__customerFamilyNameInput: 'Введите Фамилию',

					formLabel__customerGivenNameInput: 'Имя',
					formPlaceholder__customerGivenNameInput: 'Введите Имя',

					formLabel__customerCountrySelect: 'Страна',

					formLabel__emailInput: 'Email адрес',
					formPlaceholder__emailInput: 'contact@domain.com',

					formLabel__websiteInput: 'Вебсайт',
					formPlaceholder__websiteInput: 'domain.com',

					formLabel__countryOfInterest: 'Интересующие страны',
					formPlaceholder__countryOfInterest: 'Начните вводить название страны',
					formHelpText__countryOfInterest: 'Выбраны страны:',

					formLabel__businessType: 'Тип бизнеса',

					formLabel__Notes:'Комментарии',
					formPlaceholder__Notes: 'Добавляйте заметки, чтобы легче было вспомнить, о чем был разговор',

					string_InterestedIn: 'Заинтересованность',


					interfaceString__retailer: 'Розничный продавец',
					interfaceString__department_store: 'Универмаг',
					interfaceString__multichannel: 'Мультиканальный',
					interfaceString__distributor: 'Дистрибьютор',
					interfaceString__retailer_department_store: 'Розничный продавец или универмаг',
					interfaceString__online_platform: 'Онлайн платформа',
					interfaceString__pharma_clinics_dermatologist: 'Сеть аптек, клиника или дерматолог',
					interfaceString__beauticians: 'Косметолог',

					formLabel__otherBusinessType: 'Другой тип бизнеса',
					formPlaceholder__otherBusinessType: 'Вы можете ввести любой отсутствующий тип',

					interfaceString__1_image: 'Изображение',
					interfaceString__2_image: 'Изображения',
					interfaceString__5_image: 'Изображений',



					notificationDescription__syncDataCompleted:'Данные успешно синхронизированы',
					notificationDescription__syncDataError: 'Ошибка синхронизации данных',

					notificationDescription__sentDataToServer: 'Данные сохранены в облаке',
					notificationDescription__saveDataLocally: 'Данные сохранены на устройстве',
					notificationDescription__deleteStagedForm: 'Элемент успешно удален',

					LEAD: 'ЛИД',
					newLead: 'Новый лид',

					formLabel__FilesAttached: 'Прикрепленные файлы',

					stringBadRouting: 'Несуществующий путь ...',
					stringLeadNotFound: 'Лид не найден',
					stringComponentNotFound: 'Компонент не найден',


					formLabel__Priority: 'Приоритет',
					interfaceString__leadFromExhibition: 'С какой выставки',

					formHeading__InterestedIn: 'Заинтересован в проектах',
					formDescription__InterestedIn: 'Отметьте галочками проекты, в которых заинтересован клиент',

					formHeading__ContactInformation: 'Основная информация',
					formDescription__ContactInformation: 'Общая и контактная информация',

					formLabel__contactData: 'Контактная информация',

					formHeading__AdditionalInformation: 'Дополнительная информация',
					formDescription__AdditionalInformation: 'Дополнительная информация важная для проекта EVENSWISS',

					formHeading__Notes: 'Комментарии',
					formDescription__Notes: 'Добавляйте заметки, чтобы легче было вспомнить, о чем был разговор',

					formHeading__Files: 'Прикрепленные файлы',
					formDescription__Files: 'Вы можете прикрепить фото визитных карточек, проспектов или буклетов...',

					formLabel__dropFilesHere: 'Перетащите сюда файлы',

					formLabel__uploadAFile:'Загрузите файл',
					string__orDragAndDrop:'или перетащите',

					string__filesFormatsDescription:'PNG, JPG, GIF до 10MB',

					interfaceString__Click_to_remove: 'Кликните, чтобы удалить',

					priority_none: 'Нет приоритета',
					priority_lowest: 'Самый низкий приоритет',
					priority_low: 'Низкий приоритет',
					priority_normal: 'Обычный приоритет',
					priority_medium: 'Средний приоритет',
					priority_high: 'Высокий приоритет',
					priority_highest: 'Наивысший приоритет',

					string_Priority: 'Приоритет',

					string_leadDeleteHeading: 'удалить Лид?',
					string_leadDeleteDescription: 'Вы уверены, что хотите удалить Лид и все связанные с ним данные?',
					string_leadDeleteButtonConfirm: 'ДА - Удалить',
					string_leadDeleteButtonCancel: 'НЕТ — Отменить',

					formHeading__followingTasks: 'Дальнейшие задачи',
					formLabel__dataChecked: 'Данные проверены',
					formLabel__followUpSent: 'Последующее электронное письмо отправлено',

					notificationHeader__error: 'Ошибка',
					notificationHeader__warning: 'Внимание',
					notificationHeader__success: 'Успех',
					notificationHeader__info: 'Информация',

					notificationDescription__leadSavedSuccessfully: 'Лид успешно сохранён',
					notificationDescription__leadNotSaved: 'Лид не сохранён',
					notificationDescription__leadDeletedSuccessfully: 'Лид успешно удалён',
					notificationDescription__leadNotDeleted: 'Лид не удалён',

					notificationDescription__componentSavedSuccessfully: 'Компонент успешно сохранён',
					notificationDescription__componentNotSaved: 'Компонент не сохранён',
					notificationDescription__componentDeletedSuccessfully: 'Компонент успешно удалён',
					notificationDescription__componentNotDeleted: 'Компонент не удалён',

					interfaceString__headingFilter: 'Фильтрация списка',
					interfaceString__openFilter: 'Раскрыть фильтр',
					interfaceString__dataFiltered: 'Данные отфильтрованы',
					interface_string__reset_filter: 'Сбросить фильтр',


					interfaceString__exportToExcel: 'Экспорт в XLS',

					formLabel__createdBy: 'Кем создан элемент',



					string_not_found_or_not_match_criteria: 'Ничего не найдено или не удовлетворяет критериям фильтрации',

					interfaceString__Archive: 'Архивировать',

					notificationDescription__leadArchivedSuccessfully: 'Лид отправлен в архив',
					notificationDescription__leadNotArchived: 'Ошибка архивации лида',
					string_leadIsArchived: 'Лид находится в архиве',

					formLabel__withArchived: 'С архивированными',

					form_label__category: 'Категория',
					formLabel__titleInput: 'Название',
					formLabel__descriptionInput: 'Описание',
					formLabel__description: 'Описание',

					formHeading__ComponentInformation: 'Данные компонента',
					formDescription__ComponentInformation: 'Основные данные компонента',
					formLabel__general_info: 'Основная информация',

					form_placeholder__titleInput: 'Название',
					formPlaceholder__descriptionInput: 'Описание',

					formLabel__id: 'ID номер',
					form_label__title: 'Название',

					form_label__contact_name: 'Имя контакта',
					form_placeholder__contact_name: 'Имя контакта',

					form_label__SKU: 'АРТ',
					form_placeholder__SKU: 'АРТ',

					form_placeholder__id: 'ID номер',
					form_placeholder__title: 'Название',

					newComponent: 'Новый компонент',

					found_in_leads: 'Найдено в Лидах',
					found_in_customers: 'Найдено в Клиентах',
					found_in_projects: 'Найдено в Проектах',
					found_in_components: 'Найдено в Компонентах',
					found_in_products: 'Найдено в Товарах',
					found_in_formulations: 'Найдено в Формулах',
					found_in_production: 'Найдено в Производстве',
					found_in_tasks: 'Найдено в Задачах',
					found_in_invoices: 'Найдено в Счетах',
					found_in_files: 'Найдено в Файлах',
					found_in_services: 'Найдено в сервисах'

				},
			}
		};
	},
	computed: {
		$interfaceLanguage: {
			get: function () {
				return this.$store.state.interfaceLanguage;
			},
			set: function (newValue) {
				this.$store.state.interfaceLanguage = newValue
				localStorage.interfaceLanguage = newValue;
			}
		}
	},
	methods: {
		$t(string_index, language = null) {
			//вернуть перевод строки или английскую версию или сам индекс!
			let ln = (language) ? language : this.$interfaceLanguage
			if (this.translation[ln] && this.translation[ln][string_index]) return this.translation[ln][string_index];
			else if (this.translation[ln] && !this.translation[ln][string_index] && this.translation.en[string_index]) {
				//console.warn('no_to: '+ln+ ' ' + string_index)
				return '*' + this.translation.en[string_index] + '*';
			} else {
				//console.log('no_string: ' + string_index)
				return '*' + string_index.toString() + '*';
			}
		},
		$pluralize(count, string_1, string_2, string_5) {
			count  = Math.abs(count) % 100
			let count_1 = count % 10
			if (count > 10 && count < 20) return string_5
			if (count_1 > 1 && count_1 < 5) return string_2
			if (count_1 === 1) return string_1
			return string_5
		}
	}
}
