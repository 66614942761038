<template>
	<TransitionRoot as="template" :show="updateExists">
		<Dialog as="div" class="relative z-50">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
			</TransitionChild>
			
			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100"
					                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
							<div class="sm:flex sm:items-start">
								<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true"/>
								</div>
								<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ $t('modalHeader__App_update_available') }}</DialogTitle>
									<div class="mt-2">
										<p class="text-sm text-gray-500">{{ $t('interfaceString__App_update_available') }}</p>
									</div>
								</div>
							</div>
							<div class="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
								<button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm" @click="refreshApp">{{ $t('modalButton__Update') }}</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import AppUpdateMixin from '@/mixins/AppUpdateMixin'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline'

export default {
	name: "AppUpdate",
	mixins: [AppUpdateMixin],
	components: {
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, ExclamationTriangleIcon
	},
	computed: {
		hasNewerVersion() {
			return this.updateExists
		}
	}
}

</script>
