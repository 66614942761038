<template>
	<div class="ml-4 flex items-center md:ml-6">
		<button @click.prevent="triggerNotificationToast = true" type="button" class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
			<span class="sr-only">View notifications</span>
			<BellIcon class="h-6 w-6" aria-hidden="true"/>
		</button>
	</div>
</template>

<script setup>
import {ref} from "vue";
import {
	BellIcon
} from '@heroicons/vue/24/outline'

let triggerNotificationToast = ref(false)
</script>
