<template>
	<div class="grow">
		<template v-if="!localDataLoading">
			<div>
				<div class="mb-4" v-if="content.priority">
					<span :class="'bg-'+priorityLevels[content.priority].color+'-100 text-'+priorityLevels[content.priority].color+'-800'" class="inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium">{{ $t('priority_' + priorityLevels[content.priority].key) }}</span>
				</div>
				
				<h3 class="text-2xl font-semibold leading-6 text-gray-900">
					<!-- {{exhibitionList[content.exhibition].name}}-->
					{{ leadName(content) }}
				</h3>
				<p class="mt-1 max-w-2xl text-base text-gray-500" v-if="content.country_name">{{ content.country_name }}</p>
			</div>
			<div class="mt-5 border-t border-gray-200">
				<dl class="sm:divide-y sm:divide-gray-200">
					<div v-if="+content.interest_evenswiss || +content.interest_ucls || +content.interest_alove || +content.interest_suissette" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('string_InterestedIn') }}:</dt>
						<dd class="-mt-2 text-sm text-gray-900 sm:col-span-2">
							<span v-if="+content.interest_evenswiss" class="inline-block mt-2 items-center rounded bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 mr-2">EVENSWISS</span>
							<span v-if="+content.interest_suissette" class="inline-block mt-2 items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-blue-800 mr-2">SUISSETTE</span>
							<span v-if="+content.interest_alove" class="inline-block mt-2 items-center rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium text-blue-800 mr-2">ALÔVE</span>
							<span v-if="+content.interest_ucls" class="inline-block mt-2 items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 mr-2">UCLS</span>
						</dd>
					</div>
					<div v-if="+countries_selected.length" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__countryOfInterest') }}:</dt>
						<dd class="-mt-2 text-sm text-gray-900 sm:col-span-2">
							<span v-for="country_selected in countries_selected" class="inline-block mr-2 mt-2 items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">{{ countriesData[country_selected].title }}</span>
						</dd>
					</div>
					<div v-if="+customer_business_types.length || content.other_type_of_business" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__businessType') }}:</dt>
						<dd class="-mt-2 text-sm text-gray-900 sm:col-span-2">
							<template v-if="+customer_business_types.length">
								<span v-for="business_type in customer_business_types" class="inline-block mr-2 mt-3 items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">{{ $t('interfaceString__' + business_type) }}</span>
							</template>
							<span class="inline-block mr-2 mt-2 items-center rounded-md bg-blue-50 px-2.5 py-0.5 text-sm font-medium text-gray-800" v-if="content.other_type_of_business">{{ content.other_type_of_business }}</span>
						</dd>
					</div>
					
					<div v-if="content.given_name || content.family_name" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('string_FullName') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							{{ content.given_name }}
							{{ content.family_name }}
						</dd>
					</div>
					<div v-if="content.email" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__emailInput') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 underline">
							<a :href="'mailto:'+content.email">{{ content.email }}</a>
						</dd>
					</div>
					<div v-if="content.website" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__websiteInput') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 underline">
							<a :href="'//'+content.website" target="_blank">{{ content.website }}</a>
						</dd>
					</div>
					<div v-if="content.notes" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__Notes') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							{{ content.notes }}
						</dd>
					</div>
					
					<!-- fileLoading: {{fileLoading}}-->
					<div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5" v-if="+files.length">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__FilesAttached') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 bg-slate-50">
							<ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
								<li v-for="file in files" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm cursor-pointer" @click="downloadFile(file)">
									<div class="flex w-0 flex-1 items-center">
										<PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
										<span class="ml-2 w-0 flex-1 truncate underline">{{ file.name }}</span> {{ formatFileSize(file.size) }}
									</div>
								</li>
							</ul>
						</dd>
					</div>
				</dl>
			</div>
			<pre v-if="+logs.length">{{ logs }}</pre>
		</template>
	</div>
</template>

<script>
import {PaperClipIcon} from '@heroicons/vue/24/outline'
import {mapState} from "vuex"
import LeadsMixin from "@/mixins/LeadsMixin";

export default {
	name: "Lead",
	components: {
		PaperClipIcon
	},
	mixins: [LeadsMixin],
	props: {
		content: {
			type: Object,
			default: {}
		},
		files: {
			type: Array,
			default: []
		},
		logs: {
			type: Array,
			default: []
		},
		showUpdated: {
			type: Boolean,
			default: false
		},
		isFullscreen: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState(['localDataLoading', 'fileLoading', 'exhibitionList', 'priorityLevels', 'countriesData', 'customerBusinessTypesData', 'managers']),

		countries_selected: function () {
			if (!this.content.countries_selected) return []
			return (typeof this.content.countries_selected === 'string' || this.content.countries_selected instanceof String) ? this.content.countries_selected.split(',') : this.content.countries_selected
		},

		customer_business_types: function () {
			if (!this.content.customer_business_types) return []
			return (typeof this.content.customer_business_types === 'string' || this.content.customer_business_types instanceof String) ? this.content.customer_business_types.split(',') : this.content.customer_business_types
		}
	},
	methods: {},
	mounted() {
		// console.log('if need showUpdated === true -> fetch new data')
		// console.log('fetch files on mounted')
		// console.log('=======')
		// console.log('isFullscreen: ' + this.isFullscreen)
		// console.log('showUpdated: ' + this.showUpdated)
		// console.table(this.content.id)
	}
}
</script>

<style scoped>
</style>
