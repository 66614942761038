<template>
	<div class="flex flex-shrink-0 bg-gray-600 p-4">
		<router-link to="/" class="group block w-full flex-shrink-0">
			<div class="flex items-center">
				<div>
					<img class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow" :src="userData.image" alt=""/>
				</div>
				<div class="ml-3">
					<p class="text-sm font-medium text-white">{{ userData.given_name }} {{ userData.family_name }}</p>
					<p class="text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer" @click.prevent="tryLogout()">{{ $t('Button_Logout') }}</p>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import {mapState} from "vuex"
import api from "@/api"
export default {
	name: "userWidget",
	computed: {
		...mapState([
			'userData',
			'apiToken',
			'isOnline'
		]),
		loggedIn() {
			return Boolean (this.$store.state.apiToken && this.$store.state.userData)
		},
	},
	methods: {
		tryLogout() {
			if (localStorage.apiToken) {
				api().post('logout', {'token': localStorage.apiToken})
			}
			
			this.$store.dispatch('setApiToken', null)
			this.$store.dispatch('setUserData', [])
		},
	}
}
</script>
