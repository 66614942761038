import moment from "moment/moment"
import {mapState} from "vuex"

export default {
	data() {
		return {}
	},
	computed: {
		...mapState(['parameters'])
	},
	methods: {
		formatDate(date) {
			return moment(date).format("DD.MM.YYYY HH:mm");
		},
		formatPrice(price, in_one_box = 1) {
			return (Math.round(+price * +in_one_box) / 100)
					.toLocaleString(this.parameters.system.interface.locale_code, {
						style: "decimal",
						minimumFractionDigits: 2
					});
		},
	}
}
