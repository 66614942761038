<template>
	<div class="grow relative">
		<!-- <DataLoading :loading="localDataLoading" :fullwidth="false"/>-->
		<div v-if="!globalDataLoading">
			<div class="space-y-3">
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__Priority') }}
					<select v-model="filter.priority" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(priority, priorityKey) in priorityLevels" :key="priorityKey" :value="+priorityKey">{{ $t('priority_' + priority.key) }}</option>
					</select>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<fieldset>
					<div class="mt-1 space-y-2">
						<h3 class="block text-sm font-bold text-gray-700">{{ $t('formHeading__InterestedIn') }}</h3>
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="interest_evenswiss" :true-value="1" :false-value="null" v-model="filter.interest_evenswiss" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="interest_evenswiss" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__interestEVENSWISS') }}</label>
							</div>
						</div>
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="interest_suissette" :true-value="1" :false-value="null" v-model="filter.interest_suissette" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="interest_suissette" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__interestSUISSETTE') }}</label>
							</div>
						</div>
						
						
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="interest_alove" :true-value="1" :false-value="null" v-model="filter.interest_alove" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="interest_alove" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__interestALOVE') }}</label>
							</div>
						</div>
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="interest_ucls" :true-value="1" :false-value="null" v-model="filter.interest_ucls" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="interest_ucls" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__interestUCLS') }}</label>
							</div>
						</div>
					</div>
				</fieldset>
				
				<fieldset>
					<div class="mt-1 space-y-2">
						<h3 class="block text-sm font-bold text-gray-700">{{ $t('formHeading__followingTasks') }}</h3>
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="data_checked" :true-value="1" :false-value="null" v-model="filter.data_checked" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="data_checked" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__dataChecked') }}</label>
							</div>
						</div>
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="follow_up" :true-value="1" :false-value="null" v-model="filter.follow_up" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="follow_up" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__followUpSent') }}</label>
							</div>
						</div>
					</div>
				</fieldset>
				
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__companyNameInput') }}
					<input v-model.trim="filter.company_name" autocomplete="no-autocomplete" :placeholder="$t('formPlaceholder__companyNameInput')" type="text"
					       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
				</label>
				
				
				<label class="block text-sm font-medium text-gray-700">{{$t('formLabel__customerCountrySelect')}}
					<input v-model.trim="filter.country_name" autocomplete="no-autocomplete" :placeholder="$t('formPlaceholder__countryOfInterest')" type="text"
					       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300" />
				</label>
				
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__emailInput') }}
					<input v-model.trim="filter.email" autocomplete="no-autocomplete" :placeholder="$t('formPlaceholder__emailInput')" type="email"
					       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<div class="grid grid-cols-6 gap-3">
					<!-- family_name -->
					<div class="col-span-6 sm:col-span-6">
						<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__customerFamilyNameInput') }}
							<input v-model.trim="filter.family_name" autocomplete="no-autocomplete" :placeholder="$t('formPlaceholder__customerFamilyNameInput')" type="text"
							       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
						</label>
					</div>
					<!-- given_name -->
					<div class="col-span-6 sm:col-span-6">
						<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__customerGivenNameInput') }}
							<input v-model.trim="filter.given_name" autocomplete="no-autocomplete" :placeholder="$t('formPlaceholder__customerGivenNameInput')" type="text"
							       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
						</label>
					</div>
				</div>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('interfaceString__leadFromExhibition') }}
					<select v-model="filter.exhibition" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(exhibition, exhibitionKey) in exhibitionList" :key="exhibitionKey" :value="exhibitionKey">{{ exhibition.name }}</option>
					</select>
				</label>
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__createdBy') }}
					<select v-model="filter.created_by" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(manager, managerKey) in managers" :key="managerKey" :value="managerKey">{{ manager.given_name + ' ' + manager.family_name }}</option>
					</select>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				
				<fieldset>
					<div class="mt-1 space-y-2">
						<div class="flex items-start">
							<div class="flex h-5 items-center">
								<input id="with_archived" :true-value="1" :false-value="null" v-model="filter.with_archived" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"/>
							</div>
							<div class="ml-3 text-sm cursor-pointer select-none">
								<label for="with_archived" class="font-medium text-gray-700 cursor-pointer">{{ $t('formLabel__withArchived') }}</label>
							</div>
						</div>
					</div>
				</fieldset>
				
			
			
			</div>
			
<!--			<pre>{{ filter }}</pre>-->
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex"
import DataLoading from "@/components/helpers/DataLoading"
import LeadsMixin from "@/mixins/LeadsMixin";

export default {
	name: "LeadsListFilter",
	components: {
		DataLoading
	},
	mixins: [LeadsMixin],
	props: {
		filter: Object
	},
	computed: {
		...mapState(['globalDataLoading', 'localDataLoading', 'fileLoading', 'exhibitionList', 'priorityLevels', 'countriesData', 'customerBusinessTypesData', 'managers'])
	},
	methods: {
	},
	mounted() {
		//console.log('LEADS LIST FILTER MOUNTED')
	}
}
</script>

<style scoped>
</style>
