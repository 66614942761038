<template>
	<div class="flex h-full flex-col justify-center content-center py-12 sm:px-6 lg:px-8 bg-slate-500" :class="{'hidden':globalDataLoading}">
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-slate-600 py-8 px-4 shadow sm:rounded-lg sm:px-10">
				<div>
					<div id="google_button_wrapper" class="flex justify-center" style="min-height: 40px; max-height: 40px; overflow: hidden">
					
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex"
import api from "@/api";

export default {
	name: "googleLogin",
	data() {
		return {}
	},
	computed: {
		...mapState([
			'globalDataLoading'
		]),
		loggedIn() {
			return Boolean(this.$store.state.apiToken && this.$store.state.userData)
		},
	},
	methods: {
		initGoogleScript() {
			const id = 'google_login_button_script'
			if (document.getElementById(id) === null) {
				let script = document.createElement('script')
				script.setAttribute('src', 'https://accounts.google.com/gsi/client')
				script.setAttribute('id', id)
				document.body.appendChild(script)
				
				script.onload = () => {
					this.renderGoogleButton()
				}
			} else this.renderGoogleButton()
		},
		renderGoogleButton() {
			let wrapper_width = document.getElementById("google_button_wrapper").offsetWidth
			google.accounts.id.initialize({
				client_id: "181811981981-nr30lilhq89e60q0sml9tmu9j6sj8ph4.apps.googleusercontent.com",
				callback: this.handleCredentialResponse
			});
			google.accounts.id.renderButton(
				document.getElementById("google_button_wrapper"),
				{
					// auto_select: true
					// type: "standard",
					theme: "filled_blue",
					shape: "rectangular",
					size: "large",
					logo_alignment: "left",
					text: "signin_with",
					width: wrapper_width
				}
			)
			
			//#google_button_wrapper #container
		},
		decodeJwtResponse(token) {
			let base64Url = token.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			
			return JSON.parse(jsonPayload);
		},
		handleCredentialResponse(response) {
			if (response.credential) {
				//TODO: Global data loading
				this.$store.dispatch('setGlobalDataLoading', true)
				const responseDecoded = this.decodeJwtResponse(response.credential)
				
				// console.log("ID: " + responseDecoded.sub)
				// console.log('Full Name: ' + responseDecoded.name)
				// console.log('Given Name: ' + responseDecoded.given_name)
				// console.log('Family Name: ' + responseDecoded.family_name)
				// console.log("Image URL: " + responseDecoded.picture)
				// console.log("Email: " + responseDecoded.email)
				//
				// console.table(responseDecoded)
				
				api().post('google-login', {'google_token': response.credential}).then(async (response) => {
					if (response.data.apiToken && response.data.userData) {
						this.$store.dispatch('setApiToken', response.data.apiToken)
						this.$store.dispatch('setUserData', response.data.userData)

						this.messageHeader = null
						this.messagesDescription = null
						// this.$store.dispatch('syncData', {silent: true, redirect: ''})

						//setTimeout(() => this.fetchInitialData(), 1000)
						await this.fetchInitialData()
					}
					this.$store.dispatch('setGlobalDataLoading', false)
				}).catch(e => {
					console.log(e)
					this.$store.dispatch('setApiToken', null)
					this.$store.dispatch('setUserData', [])
				}).finally(() => this.$store.dispatch('setGlobalDataLoading', false))
			}
		}
	},
	mounted() {
		this.initGoogleScript()
	}
}
</script>
