<template>
	<div class="h-full relative">
		<template v-if="!globalDataLoading">
		<AppUpdate/>
		<LoginForm v-if="!loggedIn"/>
		<template v-else>
			<SearchModal :open="globalSearchEnabled"/>
			<TransitionRoot as="template" :show="mobileMenuOpen">
				<Dialog as="div" class="relative z-40 lg:hidden" @close="mobileMenuOpen = false">
					<TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
						<div class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
					</TransitionChild>
					<div class="fixed inset-0 z-40 flex">
						<TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
							<DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-700 pb-4">
								<TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
									<div class="absolute top-0 right-0 -mr-12 pt-2">
										<button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="mobileMenuOpen = false">
											<span class="sr-only">Close sidebar</span>
											<XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
										</button>
									</div>
								</TransitionChild>
								<UserWidget/>
								<nav class="mt-5 h-full flex-shrink-0 divide-y divide-gray-800 overflow-y-auto" aria-label="Sidebar">
									<NavigationPrimary/>
									<NavigationSecondary/>
									<!--								<UsersOnline/>-->
								</nav>
							</DialogPanel>
						</TransitionChild>
						<div class="w-14 flex-shrink-0" aria-hidden="true">
							<!-- Dummy element to force sidebar to shrink to fit close icon -->
						</div>
					</div>
				</Dialog>
			</TransitionRoot>
			
			<!-- Static sidebar for desktop -->
			<div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col z-50">
				<UserWidget/>
				<div class="flex flex-grow flex-col overflow-y-auto bg-gray-700 pt-5 pb-4">
					<nav class="flex flex-1 flex-col divide-y divide-gray-800 overflow-y-auto" aria-label="Sidebar">
						<NavigationPrimary/>
						<NavigationSecondary/>
					</nav>
				</div>
				<div class="flex flex-shrink-0 bg-gray-700 text-gray-500 px-4 py-3 text-xs select-none">
					{{ AppVersion }}
				</div>
				<!--			<UsersOnline/>-->
			</div>
			
			<div class="lg:pl-64 h-full">
				<div class="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none sticky top-0 shadow z-40">
					<!-- Main menu opening button -->
					<button type="button" class="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden" @click="mobileMenuOpen = true">
						<span class="sr-only">Open sidebar</span>
						<Bars3CenterLeftIcon class="h-6 w-6" aria-hidden="true"/>
					</button>
					
					<div class="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:px-6">
						<SearchField/>
						<!--						<NotificationsArea/>-->
						<QuickActions/>
					</div>
				</div>
				<main class="relative bg-white">
					<RouterView/>
				</main>
			</div>
			
			<SlideOver :open="slideOverStatus" :type="slideOverContentType" :content="slideOverContent" :dimming="slideOverDimming" :direction="slideOverDirection" @close="$store.dispatch('triggerSlideOver', false)"/>
			<ModalWindow :open="modalWindowStatus" @close="$store.dispatch('triggerModalWindow', false)"/>
			<!--		<NotificationToast :show="triggerNotificationToast"/>-->
		</template>
		<AppNotifications/>
		</template>
		<DataLoading :loading="globalDataLoading"/>
	</div>
</template>

<script>
import api from "@/api"
// import moment from "moment"

// import DatePick from "@/plugins/vue-date-pick/vueDatePick";
// import {datePickerLocalization} from "@/plugins/vue-date-pick/vueDatePickLocalization";
// import "@/plugins/vue-date-pick/vueDatePick.css";

import {mapState} from 'vuex'
import {
	Dialog,
	DialogPanel,
	TransitionRoot,
	TransitionChild
} from '@headlessui/vue'

import {
	Bars3CenterLeftIcon,
	XMarkIcon,
} from '@heroicons/vue/24/outline'


import QuickActions from "@/components/interface/QuickActions"
import SearchField from "@/components/interface/SearchField"
import UsersOnline from "@/components/interface/UsersOnline"
import NotificationsArea from "@/components/interface/NotificationsArea"
import UserWidget from "@/components/interface/UserWidget"
import NavigationPrimary from "@/components/interface/NavigationPrimary"
import NavigationSecondary from "@/components/interface/NavigationSecondary"
import LoginForm from "@/components/interface/LoginForm"
import DataLoading from "@/components/helpers/DataLoading"
import AppUpdate from "@/components/helpers/AppUpdate"
import SearchModal from "@/components/interface/SearchModal"

import SlideOver from "@/components/modules/SlideOver"
import ModalWindow from "@/components/modules/ModalWindow"
import AppNotifications from "@/components/helpers/AppNotifications.vue";
// let triggerSlideOver = ref(false)
//
// import NotificationToast from "@/components/NotificationToast"
// let triggerNotificationToast = ref(false)
//

// let triggerModalWindow = ref(false)

export default {
	name: 'App',
	components: {
		AppNotifications,
		AppUpdate,
		DataLoading,
		Dialog, DialogPanel, TransitionRoot, TransitionChild,
		Bars3CenterLeftIcon, XMarkIcon,

		QuickActions,

		SearchField,
		UsersOnline,
		NotificationsArea,
		UserWidget,
		NavigationPrimary,
		NavigationSecondary,
		LoginForm,

		SearchModal,

		SlideOver,
		ModalWindow
	},
	data() {
		return {
			mobileMenuOpen: false
		}
	},
	methods: {
		checkToken() {
			this.$store.dispatch('setGlobalDataLoading', true)
			if (localStorage.apiToken) {
				api().post('token', {'token': localStorage.apiToken}).then(async (response) => {
					if (response.data.apiToken && response.data.userData) {
						this.$store.dispatch('setApiToken', response.data.apiToken)
						this.$store.dispatch('setUserData', response.data.userData)
						//this.$store.dispatch('setSystemInitialData', response.data.SystemData)

						await this.fetchInitialData()
					} else this.$store.dispatch('setGlobalDataLoading', false)
				}).catch(e => {
					this.$store.dispatch('setApiToken', null)
					this.$store.dispatch('setUserData', [])
				}).finally(() => this.$store.dispatch('setGlobalDataLoading', false))
			} else {
				this.$store.dispatch('setApiToken', null)
				this.$store.dispatch('setUserData', [])
				this.$store.dispatch('setGlobalDataLoading', false)
			}
		},
	},
	computed: {
		...mapState([
			'AppVersion',
			'isOnline',
			'hasNewerVersion',

			'apiToken',
			'userData',

			'globalDataLoading',
			'localDataLoading',

			'slideOverStatus',
			'slideOverDimming',
			'slideOverDirection',
			'slideOverContent',
			'slideOverContentType',

			'modalWindowStatus',
			'globalSearchEnabled'
		]),
		loggedIn() {
			return Boolean(this.$store.state.apiToken && this.$store.state.userData)
		},
	},
	async mounted() {
		document.title = 'UT OEM SYSTEM'

		window.addEventListener('offline', () => {
			// console.log('OFFLINE')
			this.$store.commit('updateOnlineStatus', false)
		})
		window.addEventListener('online', () => {
			this.$store.commit('updateOnlineStatus', true)
			if (this.loggedIn) this.$store.dispatch('syncData', {silent: true, redirect: ''})
		})

		const self = this
		const counters_channel = pusher.subscribe('counters');
		counters_channel.bind('update-counter', function (data) {
			// console.log(data)
			if (data.leads) self.$store.dispatch('setLeadsCounter', +data.leads)
		});
	},
	async created() {

		if (this.isOnline) this.checkToken()

		if (+localStorage.isDarkMode) this.$store.dispatch('toggleDarkMode', true)

		let navigatorInterfaceLanguage = (navigator.language) ? navigator.language.slice(0, 2).toLowerCase() : ''
		this.$interfaceLanguage = (localStorage.interfaceLanguage && Boolean(this.$store.state.locales.find(element => element.code === localStorage.interfaceLanguage))) ? localStorage.interfaceLanguage : ((navigatorInterfaceLanguage && Boolean(this.$store.state.locales.find(element => element.code === navigatorInterfaceLanguage))) ? navigatorInterfaceLanguage : this.$store.state.fallbackLocale)

		if (navigator.storage && navigator.storage.persist) {
			this.$store.state.isPersisted = await navigator.storage.persist()
		}
	},
	beforeUnmount() {

	}
}

</script>
