<template>
	<TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
		<Dialog as="div" class="relative z-50" @close="$store.dispatch('setGlobalSearchEnabled', false); open = false">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity"/>
			</TransitionChild>
			
			<div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
					<DialogPanel class="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
						<Combobox @update:modelValue="onSelect">
							<div class="relative border-b border-gray-100">
								<MagnifyingGlassIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true"/>
								<ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." @change="query = $event.target.value; onSearch()"/>
							</div>
							
							<div v-if="query === '' || isShort" class="py-14 px-6 text-center text-sm sm:px-14">
								<p class="mt-4 font-semibold text-gray-900">{{ $t('global_search_title') }}</p>
								<p class="mt-2 text-gray-500">{{ $t('global_search_description') }}</p>
							</div>
							<div class="relative">
								<DataLoading :loading="searchResultsLoading" :fullwidth="false"/>
								<ComboboxOptions v-if="!isShort && results === 'object'" static class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2">
									<template v-for="(results, category) in searchResults" :key="category">
										<li v-if="+results.length">
											<h2 class="-mx-4 pl-4 text-base font-medium text-gray-900 1">{{ $t('found_in_' + category) }}</h2>
											<ul class="-mx-2 mt-2 text-sm text-gray-700">
												<ComboboxOption v-for="item in results" :key="item.id" :value="item" as="template" v-slot="{ active }">
													<li @click="goRoute('/'+modules_data[category].path+'/'+item.id)" :class="['flex select-none items-center px-3 py-2 cursor-pointer rounded-md', active && 'bg-gray-100']">
														<component :is="modules_data[category].icon" :class="['h-6 w-6 flex-none', active ? 'text-gray' : 'text-gray-500']" aria-hidden="true"/>
														<span class="ml-3 flex-auto truncate">
															<span class="flex content-center" v-if="category === 'services'">
																<span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2" v-if="item.category">
																	{{ $t('service_category_' + item.category) }}
																</span>
																<span class="text-base font-medium inline-flex items-center">
																	{{ item.title }}
																</span>
																<span class="grow ml-2 font-bold inline-flex items-center justify-items-end" v-if="+item.price">
																	<span class="grow text-right">{{ formatPrice(item.price) }}</span>
																</span>
															</span>
															
															<span class="flex flex-col" v-else-if="category === 'formulations'">
																<span class="text-base font-medium inline-flex items-center pb-1">
																	<span class="text-gray-400">{{ item.SKU }}:&nbsp;</span> {{ item.title }}
																</span>
																<span class="flex content-center">
																	<span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2" v-if="item.category">
																		{{ $t('formulation_category_' + item.category) }}
																	</span>
																	
																	<template v-if="item.type">
																		<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-bold text-gray-800 ring-1 ring-inset ring-gray-500/10" :class="item.type+'_type'">{{ $t('formulation_type_' + item.type) }}</span>
																		<span v-if="item.subtype" class="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ $t('formulation_type_' + item.subtype) }}</span>
																	</template>
																
																	<span class="grow ml-2 font-bold inline-flex items-center justify-items-end" v-if="+item.price">
																		<span class="grow text-right">{{ formatPrice(item.price) }}</span>
																	</span>
																</span>
																
															</span>
															
															<template v-else>
																<span class="text-gray-400">{{ item.id.toString().padStart(4, '0') }}:&nbsp;</span>
																<span class="font-medium">
																	<template v-if="item.contact_name">{{ item.contact_name }}</template>
																	<template v-else>{{ item.title }}</template>
																</span>
															</template>
														</span>
													</li>
												</ComboboxOption>
											</ul>
										</li>
									</template>
								</ComboboxOptions>
							</div>
							
							<div v-if="query !== '' && !isShort && results === 0" class="py-14 px-6 text-center text-sm sm:px-14">
								<FaceFrownIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
								<p class="mt-4 font-semibold text-gray-900">{{ $t('no_results_found_title') }}</p>
								<p class="mt-2 text-gray-500">{{ $t('no_results_found_description') }}</p>
							</div>
						
						</Combobox>
					</DialogPanel>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>


<script>
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid'
import {
	CheckCircleIcon,
	BeakerIcon,
	ClipboardDocumentListIcon,
	Cog8ToothIcon,
	DocumentChartBarIcon,
	DocumentDuplicateIcon,
	FaceFrownIcon,
	FolderIcon,
	HomeIcon,
	InboxStackIcon,
	ListBulletIcon,
	QuestionMarkCircleIcon,
	RectangleStackIcon,
	Squares2X2Icon,
	SquaresPlusIcon,
	UserGroupIcon,
	BriefcaseIcon
} from '@heroicons/vue/24/outline'

import {
	Combobox,
	ComboboxInput,
	ComboboxOptions,
	ComboboxOption,
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue'

import {mapState} from "vuex"

import DataLoading from "@/components/helpers/DataLoading"
import router from "@/router";

export default {
	components: {
		Combobox,
		ComboboxInput,
		ComboboxOptions,
		ComboboxOption,
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,

		MagnifyingGlassIcon,

		BeakerIcon,
		ClipboardDocumentListIcon,
		Cog8ToothIcon,
		DocumentChartBarIcon,
		DocumentDuplicateIcon,
		FaceFrownIcon,
		FolderIcon,
		HomeIcon,
		InboxStackIcon,
		ListBulletIcon,
		QuestionMarkCircleIcon,
		RectangleStackIcon,
		Squares2X2Icon,
		SquaresPlusIcon,
		UserGroupIcon,
		CheckCircleIcon,
		BriefcaseIcon,

		DataLoading
	},
	props: {
		open: Boolean,
	},
	data() {
		return {
			query: '',
			modules_data: {
				'leads': {
					path: 'lead',
					icon: 'ClipboardDocumentListIcon'
				},
				'customers': {
					path: 'customer',
					icon: 'UserGroupIcon'
				},
				'projects': {
					path: 'project',
					icon: 'InboxStackIcon'
				},
				'components': {
					path: 'component',
					icon: 'SquaresPlusIcon'
				},
				'products': {
					path: 'product',
					icon: 'Squares2X2Icon'
				},
				'formulations': {
					path: 'formulation',
					icon: 'BeakerIcon'
				},
				'production': {
					path: 'production',
					icon: 'RectangleStackIcon'
				},
				'tasks': {
					path: 'task',
					icon: 'CheckCircleIcon'
				},
				'invoices': {
					path: 'invoice',
					icon: 'DocumentDuplicateIcon'
				},
				'files': {
					path: 'file',
					icon: 'FolderIcon'
				},
				'services': {
					path: 'service',
					icon: 'ListBulletIcon'
				},
				'counterparties': {
					path: 'counterparty',
					icon: 'BriefcaseIcon'
				},
			}
		}
	},
	computed: {
		...mapState(['globalDataLoading', 'localDataLoading', 'searchResultsLoading', 'searchResults']),

		isShort() {
			//if int
			//string > 3
			return (!+this.query.isInteger && !+this.query) && this.query.length <= 3
		},
		results() {
			return (Array.isArray(this.searchResults)) ? this.searchResults.length : 'object'
		}
	},
	methods: {
		onSearch() {
			if (!this.isShort) this.fetchSearchResults(this.query)
			else {
				this.$store.dispatch('setSearchResults', [])
				this.query = ''
			}
		},
		goRoute(path) {
			this.$store.dispatch('setGlobalSearchEnabled', false)
			router.push(path)
		},
		onSelect(item) {
			//TODO: ON ENTER AND KEYBOARD
			console.log(item)
		}
	}
}
</script>
