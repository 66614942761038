<template>
	<div class="space-y-1 px-2">
		<router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[(($route.name === item.name) || ($route.name === (item.name+'-paginated')) || ($route.name === (item.name+'-new')) || ($route.name === (item.name+'-edit')) || ($route.name === (item.name+'-view')) || item.current) ? 'bg-gray-800 text-white' : 'text-gray-100 hover:text-white hover:bg-gray-600', item.disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : '', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']"
		   :aria-current="(($route.name === item.name) || ($route.name === (item.name+'-paginated')) || ($route.name === (item.name+'-new')) || ($route.name === (item.name+'-edit')) || ($route.name === (item.name+'-view')) || item.current) ? 'page' : undefined">
			<component :is="item.icon" class="mr-4 h-6 w-6 flex-shrink-0 text-gray-200" aria-hidden="true"/>
			<span class="truncate">{{ $t('menu_'+item.name) }}</span>
			<span v-if="counters && counters[item.name]" :class="[(($route.name === item.name) || ($route.name === (item.name+'-paginated')) || ($route.name === (item.name+'-new')) || ($route.name === (item.name+'-edit')) || ($route.name === (item.name+'-view')) || item.current) ? 'bg-gray-50' : 'bg-gray-200', 'text-gray-600 ml-auto inline-block py-0.5 px-3 text-xs rounded-full']">{{ counters[item.name] }}</span>
		</router-link>
	</div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const navigation = computed(() => store.state.navigationPrimary);
const counters = computed(() => store.state.counters);
</script>
