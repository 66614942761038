<template>
	<div class="grow">
		<template v-if="!localDataLoading">
			<div>
				<h3 class="text-2xl font-semibold leading-6 text-gray-900">
					{{ itemName(content) }}
				</h3>
				<p class="mt-1 max-w-2xl text-base text-gray-500" v-if="content.SKU">{{ $t('string_' + content.SKU) }}</p>
				<p class="mt-1 max-w-2xl text-base text-gray-500" v-if="content.category">{{ $t('string_' + content.category) }}</p>
			</div>
			<div class="mt-5 border-t border-gray-200">
				<dl class="sm:divide-y sm:divide-gray-200">
					<div v-if="content.description" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
						<dt class="text-sm font-medium text-gray-500">{{ $t('formLabel__description') }}:</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							{{ content.description }}
						</dd>
					</div>
				</dl>
			</div>
			<pre v-if="+logs.length">{{ logs }}</pre>
		</template>
	</div>
</template>

<script>
import {mapState} from "vuex"
import ServicesMixin from "@/mixins/ServicesMixin"

export default {
	components: {},
	mixins: [ServicesMixin],
	props: {
		content: {
			type: Object,
			default: {}
		},
		files: {
			type: Array,
			default: []
		},
		logs: {
			type: Array,
			default: []
		},
		showUpdated: {
			type: Boolean,
			default: false
		},
		isFullscreen: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState(['localDataLoading', 'fileLoading', 'managers'])
	},
	methods: {},
	mounted() {
		// console.log('if need showUpdated === true -> fetch new data')
		// console.log('fetch files on mounted')
		// console.log('=======')
		// console.log('isFullscreen: ' + this.isFullscreen)
		// console.log('showUpdated: ' + this.showUpdated)
		// console.table(this.content.id)
	}
}
</script>

<style scoped>
</style>
