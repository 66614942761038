<template>
	<div class="grow">
		<template v-if="!localDataLoading">
			<div>
				<h3 class="text-2xl font-semibold leading-6 text-gray-900">
					{{ itemName(content) }}
				</h3>
				<p class="mt-1 max-w-2xl text-base text-gray-500" v-if="content.parent_id">{{ content.parent_id }}</p>
			</div>
			<div class="mt-5 border-t border-gray-200">
			
			</div>
			<pre v-if="content.products">{{ content.products }}</pre>
			<pre v-if="+logs.length">{{ logs }}</pre>
		</template>
	</div>
</template>

<script>
import {mapState} from "vuex"
import CustomersMixin from "@/mixins/CustomersMixin"

export default {
	components: {},
	mixins: [CustomersMixin],
	props: {
		content: {
			type: Object,
			default: {}
		},
		files: {
			type: Array,
			default: []
		},
		logs: {
			type: Array,
			default: []
		},
		showUpdated: {
			type: Boolean,
			default: false
		},
		isFullscreen: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState(['localDataLoading', 'fileLoading', 'managers'])
	},
	methods: {},
	mounted() {
		// console.log('if need showUpdated === true -> fetch new data')
		// console.log('fetch files on mounted')
		// console.log('=======')
		// console.log('isFullscreen: ' + this.isFullscreen)
		// console.log('showUpdated: ' + this.showUpdated)
		// console.table(this.content.id)
	}
}
</script>

<style scoped>
</style>
