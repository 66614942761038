<template>
	<div class="ml-4 flex items-center md:ml-6 space-x-3">
		<router-link to="/lead" class="inline-flex items-center rounded-md border border-gray-400 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
			<ClipboardDocumentListIcon class="h-5 w-5" aria-hidden="true"/>
		</router-link>


<!--		-->
<!--		<router-link to="/client" class="inline-flex items-center rounded-md border border-gray-400 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">-->
<!--			<UserGroupIcon class="h-5 w-5" aria-hidden="true"/>-->
<!--		</router-link>-->





<!--		<button @click.prevent="triggerSlideOver = true" type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">-->
<!--			<BanknotesIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>-->
<!--			<span>Customer</span>-->
<!--		</button>-->
<!--		<button @click.prevent="triggerSlideOver = true" type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">-->
<!--			<BanknotesIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>-->
<!--			<span>Project</span>-->
<!--		</button>-->
	</div>
</template>

<script setup>
import {ref} from "vue";
import {
	ClipboardDocumentListIcon, UserGroupIcon
} from '@heroicons/vue/24/outline'
let triggerSlideOver = ref(false)
</script>
