import {createApp} from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import './App.css'

import Pusher from 'pusher-js'
// app_id = "1572781"
// key = "e53f3d659e1e8fd919bf"
// secret = "c0cbccb6f203c8b1eb27"
// cluster = "eu"
window.pusher = new Pusher('e53f3d659e1e8fd919bf', { cluster: 'eu' })

const app = createApp(App)

import FetchDataMixin from "./mixins/FetchDataMixin"
app.mixin(FetchDataMixin)

import TranslateMixin from "./lang/TranslateMixin"
app.mixin(TranslateMixin)

import InterfaceHelpersMixin from "./mixins/InterfaceHelpersMixin"
app.mixin(InterfaceHelpersMixin)

import ValidateMixin from "./mixins/ValidateMixin"
app.mixin(ValidateMixin)

import FormattingMixin from "./mixins/FormattingMixin"
app.mixin(FormattingMixin)


import FilesMixin from "./mixins/FilesMixin"
app.mixin(FilesMixin)

import store from "@/store"
app.use(store)

import router from "@/router"
app.use(router)

// import api from '@/api'
// app.use(api)

// import _ from "lodash"
// app.use(_)

app.mount('#app')

store.dispatch('init').then(r => {})
