import {mapState} from "vuex";

export default {
	data() {
		return {

		}
	},

	computed: {
		// _() {
		// 	return _
		// }
		...mapState([

		])
	},

	methods: {
		leadName(item) {
			let title = (+item.id) ? this.$t('LEAD') + ' #' + item.id : this.$t('newLead')
			if (item.company_name) title = item.company_name
			else if (item.given_name || item.family_name) {
				title = (item.given_name) ? item.given_name : ''
				if (item.given_name && item.family_name) title += ' '
				title += (item.family_name) ? item.family_name : ''
			} else if (item.email) title = item.email
			return title
		}
	},

	created() {

	},
}
