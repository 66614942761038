<template>
	<div class="mt-6 pt-6">
		<div class="space-y-1 px-2">
			<router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[(($route.name === item.name) || item.current) ? 'bg-gray-800 text-white' : 'text-gray-100 hover:text-white hover:bg-gray-600', item.disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : '', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']">
				<component :is="item.icon" class="mr-4 h-6 w-6 text-gray-200" aria-hidden="true"/>
				{{ $t('menu_'+item.name) }}
			</router-link>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const navigation = computed(() => store.state.navigationSecondary);
</script>
