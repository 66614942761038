<template>
	<!-- Global notification live region, render this permanently at the end of the document -->
	<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<!--
			  Notification panel, dynamically insert this into the live region when it needs to be displayed
		
			  Entering: "transform ease-out duration-300 transition"
				From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				To: "translate-y-0 opacity-100 sm:translate-x-0"
			  Leaving: "transition ease-in duration-100"
				From: "opacity-100"
				To: "opacity-0"
			-->
			<transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
				<div v-if="+notifications.length" class="pointer-events-auto w-full max-w-sm overflow-hidden space-y-2">
					<div v-for="(notification,notificationIndex) in notifications" class="pointer-events-auto user-select-none max-w-screen-lg overflow-hidden border rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 " :class="{'border-green-500 dark:border-green-200 bg-green-50 dark:bg-green-100': notification.type === 'success', 'border-red-500 dark:border-red-200 bg-red-50 dark:bg-red-100': notification.type === 'error','border-yellow-500 dark:border-yellow-200 bg-yellow-50 dark:bg-yellow-100': notification.type === 'warning','border-blue-500 dark:border-blue-200 bg-blue-50 dark:bg-blue-100': notification.type === 'info'}" @click="removeNotification(notificationIndex)">
						<div class="p-4">
							<div class="flex items-start">
								<div class="flex-shrink-0">
									<svg v-if="notification.type === 'success'" class="h-6 w-6 text-green-700" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
									</svg>
									<svg v-else-if="notification.type === 'error'" class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"/>
									</svg>
									<svg v-else-if="notification.type === 'warning'" class="h-6 w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
									</svg>
									<svg v-else class="h-6 w-6 text-blue-700" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
									</svg>
								</div>
								<div class="ml-3 w-0 flex-1 pt-0.5">
									<p class="text-sm font-medium text-gray-900">{{$t('notificationHeader__'+notification.header)}}</p>
									<p class="mt-1 text-sm text-gray-500">{{$t('notificationDescription__'+notification.message)}}</p>
								</div>
								<div v-if="notification.persist" class="ml-4 flex flex-shrink-0">
									<button type="button" class="inline-flex rounded-md bg-white text-black-500 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
										<span class="sr-only">Close</span>
										<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
											<path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "AppNotifications",
	computed: {
		...mapState(['notifications'])
	},
	methods: {
		removeNotification(notificationIndex) {
			this.$store.dispatch('removeNotification',notificationIndex)
		}
	}
}
</script>
