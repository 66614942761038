// import _ from "lodash"
import api from "@/api"
import router from "@/router"
import {mapState} from "vuex"
import moment from "moment/moment"

export default {
	data() {
		return {
			current_year: new Date().getFullYear(),

			page: 1,
			pagination: {
				per_page: 10,
				current_page: 1,
				to: 1,
				from: 1,
				last_page: 1,
				total: 1,

				path: null,
				first_page_url: null,
				last_page_url: null,
				prev_page_url: null,
				next_page_url: null

			},
			paginationLoading: false,

			lead: {
				data: {},
				files: [],
				new_files: [],
			},
			leads: {
				api_route: 'leads',

				data: [],
				filter: {
					id: null,

					priority: null,

					exhibition: null,

					interest_evenswiss: null,
					interest_ucls: null,
					interest_alove: null,
					interest_suissette: null,

					company_name: null,

					given_name: null,
					family_name: null,

					email: null,

					country_id: null,
					country_name: null,

					website: null,
					notes: null,

					//countries_selected: [], //STRING PRESENTED

					//customer_business_types: [], //STRING PRESENTED
					//other_type_of_business: null,

					created_by: null,
					created_at_from: null,
					created_at_to: null,

					follow_up: null,
					data_checked: null,

					with_archived: null
				},
				sorting: {
					order_by: 'created_at',
					order_direction: 'DESC'
				}
			}
		}
	},

	computed: {
		// _() {
		// 	return _
		// }
		...mapState([
			'isOnline',
			'uploadProgress',
			'apiToken',

			'components',
			'component',

			'products',
			'product',

			'services',
			'service',

			'counterparties',
			'counterparty',

			'formulations',
			'formulation',

			'customers',
			'customer'
		]),
		loggedIn() {
			return Boolean(this.$store.state.apiToken && this.$store.state.userData)
		}
	},

	methods: {
		async fetchInitialData() {
			if (this.isOnline && this.loggedIn) {
				await api().get('init',
					{
						headers: {
							'Authorization': 'Bearer ' + this.$store.state.apiToken
						}
					}
				).then(async (response) => {
					if (response.data.parameters) await this.$store.dispatch('setParameters', response.data.parameters)
					if (response.data.counters) await this.$store.dispatch('setCounters', response.data.counters)
					if (response.data.managers) await this.$store.dispatch('setManagers', response.data.managers)

					if (response.data.exhibitions) await this.$store.dispatch('setExhibitions', response.data.exhibitions)

					this.$store.dispatch('setGlobalDataLoading', false)
				}).catch(e => {
					//TODO: CLEAR LOGIN ON ERROR
					console.error(e)
				}).finally(() => this.$store.dispatch('setGlobalDataLoading', false))
			}
		},

		//GLOBAL SEARCH
		fetchSearchResults(query) {
			this.$store.dispatch('setSearchResultsLoading', true)
			let getParams = {
				search_query: query
			}
			let responseType = 'json'

			api().get('search',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setSearchResults', response.data)
				this.$store.dispatch('setSearchResultsLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setSearchResultsLoading', false)
			})
		},

		//LEADS
		setLeadDefaults() {
			this.lead.data = _.clone(this.$store.state.defaults.lead)
			this.lead.data.exhibition = this.$store.state.exhibitionDefault
			this.lead.files = []
		},
		fetchLead(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('lead/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.lead.data = response.data.data

					//FIX STRING TO ARRAY =(
					if (this.lead.data.customer_business_types && typeof this.lead.data.customer_business_types === 'string' || this.lead.data.customer_business_types instanceof String) this.lead.data.customer_business_types = this.lead.data.customer_business_types.split(',')
					else this.lead.data.customer_business_types = []

					//FIX STRING TO ARRAY =(
					if (this.lead.data.countries_selected && typeof this.lead.data.countries_selected === 'string' || this.lead.data.countries_selected instanceof String) this.lead.data.countries_selected = this.lead.data.countries_selected.split(',')
					else this.lead.data.countries_selected = []

					delete this.lead.data.files
				}
				if (response.data.files) this.lead.files = response.data.files
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/lead-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		saveLead() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			formData.append("form_index", this.lead.data.form_index)

			if (this.lead.data.id) formData.append("id", this.lead.data.id)

			if (this.lead.data.exhibition) formData.append("exhibition", this.lead.data.exhibition)
			if (this.lead.data.priority) formData.append("priority", this.lead.data.priority)

			if (this.lead.data.interest_evenswiss) formData.append("interest_evenswiss", this.lead.data.interest_evenswiss)
			if (this.lead.data.interest_ucls) formData.append("interest_ucls", this.lead.data.interest_ucls)
			if (this.lead.data.interest_alove) formData.append("interest_alove", this.lead.data.interest_alove)
			if (this.lead.data.interest_suissette) formData.append("interest_suissette", this.lead.data.interest_suissette)

			if (this.lead.data.company_name) formData.append("company_name", this.lead.data.company_name)
			if (this.lead.data.given_name) formData.append("given_name", this.lead.data.given_name)
			if (this.lead.data.family_name) formData.append("family_name", this.lead.data.family_name)
			if (this.lead.data.email) formData.append("email", this.lead.data.email)
			if (this.lead.data.country_id) formData.append("country_id", this.lead.data.country_id)
			if (this.lead.data.country_name) formData.append("country_name", this.lead.data.country_name)
			if (this.lead.data.website) formData.append("website", this.lead.data.website)
			if (this.lead.data.notes) formData.append("notes", this.lead.data.notes)
			if (this.lead.data.countries_selected) formData.append("countries_selected", this.lead.data.countries_selected.join(','))
			if (this.lead.data.customer_business_types) formData.append("customer_business_types", this.lead.data.customer_business_types.join(','))
			if (this.lead.data.other_type_of_business) formData.append("other_type_of_business", this.lead.data.other_type_of_business)

			if (this.lead.data.follow_up) formData.append("follow_up", this.lead.data.follow_up)
			if (this.lead.data.follow_up_at) formData.append("follow_up_at", this.lead.data.follow_up_at)

			if (this.lead.data.data_checked) formData.append("data_checked", this.lead.data.data_checked)

			if (this.lead.data.archived_at) formData.append("archived_at", this.lead.data.archived_at)
			if (this.lead.data.created_by) formData.append("created_by", this.lead.data.created_by)

			// console.log(this.lead.new_files)
			// if (+this.lead.new_files.length) {
			// 	for (let i = 0; i < +this.lead.new_files.length; i++) {
			// 		let file = this.lead.new_files[i];
			// 		formData.append('new_files[' + i + ']', file);
			// 	}
			// }

			api()
				.post(
					"form",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.lead.data = response.data.data

							// FIX STRING TO ARRAY =(
							if (this.lead.data.customer_business_types && typeof this.lead.data.customer_business_types === 'string' || this.lead.data.customer_business_types instanceof String) this.lead.data.customer_business_types = this.lead.data.customer_business_types.split(',')
							else this.lead.data.customer_business_types = []

							// FIX STRING TO ARRAY =(
							if (this.lead.data.countries_selected && typeof this.lead.data.countries_selected === 'string' || this.lead.data.countries_selected instanceof String) this.lead.data.countries_selected = this.lead.data.countries_selected.split(',')
							else this.lead.data.countries_selected = []

							delete this.lead.data.files
						}
						if (response.data.files) this.lead.files = response.data.files

						this.$store.dispatch('pushNotification', {text: 'leadSavedSuccessfully', type: 'success', persist: false})
						router.push('/lead/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'leadNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'leadNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		archiveLead() {
			this.$store.dispatch('setDataSaving', true)
			api()
				.get(
					"archive-lead/" + this.lead.data.id,
					{
						headers: {
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'leadArchivedSuccessfully', type: 'success', persist: false})
						this.$router.push("/leads")
					} else {
						this.$store.dispatch('pushNotification', {text: 'leadNotArchived', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'leadNotArchived', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		deleteLead(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"lead/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'leadDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/leads")
					} else {
						this.$store.dispatch('pushNotification', {text: 'leadNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'leadNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},
		fetchLeads(exportToXls = false) {
			//local data loading
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.leads.sorting.order_by,
				order_direction: this.leads.sorting.order_direction,

				//FILTERS
				priority: this.leads.filter.priority,

				interest_ucls: this.leads.filter.interest_ucls,
				interest_evenswiss: this.leads.filter.interest_evenswiss,
				interest_alove: this.leads.filter.interest_alove,
				interest_suissette: this.leads.filter.interest_suissette,

				data_checked: this.leads.filter.data_checked,
				follow_up: this.leads.filter.follow_up,

				company_name: this.leads.filter.company_name,
				country_name: this.leads.filter.country_name,
				email: this.leads.filter.email,

				given_name: this.leads.filter.given_name,
				family_name: this.leads.filter.family_name,

				exhibition: this.leads.filter.exhibition,
				created_by: this.leads.filter.created_by,

				with_archived: this.leads.filter.with_archived
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('leads',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.leads.data = response.data.data

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'leads-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},

		//COMPONENTS
		setComponentDefaults() {
			this.$store.dispatch('setComponent', _.clone(this.$store.state.defaults.component))
		},
		saveComponent() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.component.data.id) formData.append("id", this.component.data.id)

			if (this.component.data.title) formData.append("title", this.component.data.title)

			if (this.component.data.supplier_code) formData.append("supplier_code", this.component.data.supplier_code)


			if (this.component.data.description) formData.append("description", this.component.data.description)
			if (this.component.data.category) formData.append("category", this.component.data.category)

			if (this.component.data.created_by) formData.append("created_by", this.component.data.created_by)

			api()
				.post(
					"component",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setComponent', response.data.data)
						}

						this.$store.dispatch('pushNotification', {text: 'componentSavedSuccessfully', type: 'success', persist: false})
						router.push('/component/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'componentNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'componentNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchComponents(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.components.sorting.order_by,
				order_direction: this.components.sorting.order_direction,

				//FILTERS
				id: this.components.filter.id,
				title: this.components.filter.title,
				supplier_code: this.components.filter.supplier_code,
				category: this.components.filter.category,
				created_by: this.components.filter.created_by,
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('components',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					//if (response.data.data) this.components.data = response.data.data
					if (response.data.data) this.$store.dispatch('setComponents', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'components-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchComponentsSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.components.sorting.order_by,
				order_direction: this.components.sorting.order_direction,

				//FILTERS
				id: this.components.filter.id,
				supplier_code: this.components.filter.supplier_code,
				title: this.components.filter.title,
				category: this.components.filter.category,
				created_by: this.components.filter.created_by,
			}
			let responseType = 'json'

			api().get('components-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				//if (response.data.data) this.components.data = response.data.data
				if (response.data) this.$store.dispatch('setComponentsSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchComponent(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('component/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setComponent', response.data.data)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/component-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteComponent(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"component/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'componentDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/components")
					} else {
						this.$store.dispatch('pushNotification', {text: 'componentNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'componentNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

		//PRODUCTS
		setProductDefaults() {
			this.$store.dispatch('setProduct', _.clone(this.$store.state.defaults.product))
		},
		saveProduct() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.product.data.id) formData.append("id", this.product.data.id)

			if (this.product.data.parent_id) formData.append("parent_id", this.product.data.parent_id)
			if (this.product.data.SKU) formData.append("SKU", this.product.data.SKU)
			if (this.product.data.variant_title) formData.append("variant_title", this.product.data.variant_title)
			if (this.product.data.title) formData.append("title", this.product.data.title)
			if (this.product.data.description) formData.append("description", this.product.data.description)

			if (this.product.data.customer_id) formData.append("customer_id", this.product.data.customer_id)
			if (this.product.data.formulation_id) formData.append("formulation_id", this.product.data.formulation_id)
			if (this.product.data.project_id) formData.append("project_id", this.product.data.project_id)

			if (this.product.data.created_by) formData.append("created_by", this.product.data.created_by)

			//components
			if (this.product.data.components) formData.append("components", JSON.stringify(this.product.data.components))

			api()
				.post(
					"product",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setProduct', response.data.data)
						}

						console.log(response.data.is_new)
						console.log(response.data.changes)
						console.log(response.data.changes_by)

						this.$store.dispatch('pushNotification', {text: 'productSavedSuccessfully', type: 'success', persist: false})
						router.push('/product/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'productNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'productNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchProducts(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.products.sorting.order_by,
				order_direction: this.products.sorting.order_direction,

				//FILTERS
				id: this.products.filter.id,
				SKU: this.products.filter.SKU,
				title: this.products.filter.title,
				customer_id: this.products.filter.customer_id,
				created_by: this.products.filter.created_by
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('products',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.$store.dispatch('setProducts', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'products-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchProductsSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.products.sorting.order_by,
				order_direction: this.products.sorting.order_direction,

				//FILTERS
				id: this.products.filter.id,
				supplier_code: this.products.filter.supplier_code,
				title: this.products.filter.title,
				category: this.products.filter.category,
				created_by: this.products.filter.created_by,
			}
			let responseType = 'json'

			api().get('products-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setProductsSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchProduct(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('product/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setProduct', response.data.data)
					if (response.data.logs) {
						this.$store.dispatch('setProductLogs', response.data.logs)
					}
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/product-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteProduct(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"product/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'productDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/products")
					} else {
						this.$store.dispatch('pushNotification', {text: 'productNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'productNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

		//SERVICES
		setServiceDefaults() {
			this.$store.dispatch('setService', _.clone(this.$store.state.defaults.service))
		},
		saveService() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.service.data.id) formData.append("id", this.service.data.id)

			if (this.service.data.category) formData.append("category", this.service.data.category)

			if (this.service.data.title) formData.append("title", this.service.data.title)
			if (this.service.data.description) formData.append("description", this.service.data.description)

			if (this.service.data.price) formData.append("price", this.service.data.price)

			if (this.service.data.created_by) formData.append("created_by", this.service.data.created_by)

			api()
				.post(
					"service",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setService', response.data.data)
						}

						// console.log(response.data.is_new)
						// console.log(response.data.changes)
						// console.log(response.data.changes_by)

						this.$store.dispatch('pushNotification', {text: 'serviceSavedSuccessfully', type: 'success', persist: false})
						router.push('/service/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'serviceNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'serviceNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchServices(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.services.sorting.order_by,
				order_direction: this.services.sorting.order_direction,

				//FILTERS
				id: this.services.filter.id,
				title: this.services.filter.title,
				category: this.services.filter.category,
				created_by: this.services.filter.created_by
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('services',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.$store.dispatch('setServices', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'services-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchServicesSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.services.sorting.order_by,
				order_direction: this.services.sorting.order_direction,

				//FILTERS
				id: this.services.filter.id,
				supplier_code: this.services.filter.supplier_code,
				title: this.services.filter.title,
				category: this.services.filter.category,
				created_by: this.services.filter.created_by,
			}
			let responseType = 'json'

			api().get('services-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setServicesSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchService(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('service/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setService', response.data.data)
					if (response.data.logs) this.$store.dispatch('setServiceLogs', response.data.logs)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/service-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteService(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"service/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'serviceDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/services")
					} else {
						this.$store.dispatch('pushNotification', {text: 'serviceNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'serviceNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},


		//COUNTERPARTIES
		setCounterpartyDefaults() {
			this.$store.dispatch('setCounterparty', _.clone(this.$store.state.defaults.counterparty))
		},
		saveCounterparty() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.counterparty.data.id) formData.append("id", this.counterparty.data.id)
			if (this.counterparty.data.title) formData.append("title", this.counterparty.data.title)
			if (this.counterparty.data.description) formData.append("description", this.counterparty.data.description)

			if (this.counterparty.data.created_by) formData.append("created_by", this.counterparty.data.created_by)

			api()
				.post(
					"counterparty",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setCounterparty', response.data.data)
						}

						this.$store.dispatch('pushNotification', {text: 'counterpartySavedSuccessfully', type: 'success', persist: false})
						router.push('/counterparty/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'counterpartyNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'counterpartyNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchCounterparties(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.counterparties.sorting.order_by,
				order_direction: this.counterparties.sorting.order_direction,

				//FILTERS
				id: this.counterparties.filter.id,
				title: this.counterparties.filter.title,
				created_by: this.counterparties.filter.created_by,
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('counterparties',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					//if (response.data.data) this.counterparties.data = response.data.data
					if (response.data.data) this.$store.dispatch('setCounterparties', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'counterparties-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchCounterpartiesSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.counterparties.sorting.order_by,
				order_direction: this.counterparties.sorting.order_direction,

				//FILTERS
				id: this.counterparties.filter.id,
				title: this.counterparties.filter.title,
				created_by: this.counterparties.filter.created_by,
			}
			let responseType = 'json'

			api().get('counterparties-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setCounterpartiesSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchCounterparty(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('counterparty/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setCounterparty', response.data.data)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/counterparty-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteCounterparty(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"counterparty/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'counterpartyDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/counterparties")
					} else {
						this.$store.dispatch('pushNotification', {text: 'counterpartyNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'counterpartyNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

		//FORMULATIONS
		setFormulationDefaults() {
			this.$store.dispatch('setFormulation', _.clone(this.$store.state.defaults.formulation))
		},
		saveFormulation() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.formulation.data.id) formData.append("id", this.formulation.data.id)
			if (this.formulation.data.parent_id) formData.append("parent_id", this.formulation.data.parent_id)

			if (this.formulation.data.SKU) formData.append("SKU", this.formulation.data.SKU)

			if (this.formulation.data.type) formData.append("type", this.formulation.data.type)
			if (this.formulation.data.subtype) formData.append("subtype", this.formulation.data.subtype)

			if (this.formulation.data.category) formData.append("category", this.formulation.data.category)


			if (this.formulation.data.title) formData.append("title", this.formulation.data.title)
			if (this.formulation.data.description) formData.append("description", this.formulation.data.description)

			if (this.formulation.data.created_by) formData.append("created_by", this.formulation.data.created_by)

			api()
				.post(
					"formulation",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setFormulation', response.data.data)
						}

						// console.log(response.data.is_new)
						// console.log(response.data.changes)
						// console.log(response.data.changes_by)

						this.$store.dispatch('pushNotification', {text: 'formulationSavedSuccessfully', type: 'success', persist: false})
						router.push('/formulation/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'formulationNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'formulationNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchFormulations(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.formulations.sorting.order_by,
				order_direction: this.formulations.sorting.order_direction,

				//FILTERS
				id: this.formulations.filter.id,
				SKU: this.formulations.filter.SKU,
				title: this.formulations.filter.title,
				category: this.formulations.filter.category,
				type: this.formulations.filter.type,
				subtype: this.formulations.filter.subtype,
				created_by: this.formulations.filter.created_by
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('formulations',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.$store.dispatch('setFormulations', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'formulations-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchFormulationsSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.formulations.sorting.order_by,
				order_direction: this.formulations.sorting.order_direction,

				//FILTERS
				id: this.formulations.filter.id,
				SKU: this.formulations.filter.SKU,
				title: this.formulations.filter.title,
				category: this.formulations.filter.category,
				type: this.formulations.filter.type,
				created_by: this.formulations.filter.created_by,
			}
			let responseType = 'json'

			api().get('formulations-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setFormulationsSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchFormulation(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('formulation/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setFormulation', response.data.data)
					if (response.data.logs) this.$store.dispatch('setFormulationLogs', response.data.logs)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/formulation-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteFormulation(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"formulation/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'formulationDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/formulations")
					} else {
						this.$store.dispatch('pushNotification', {text: 'formulationNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'formulationNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

		//CUSTOMERS
		setCustomerDefaults() {
			this.$store.dispatch('setCustomer', _.clone(this.$store.state.defaults.customer))
		},
		saveCustomer() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.customer.data.id) formData.append("id", this.customer.data.id)

			if (this.customer.data.parent_id) formData.append("parent_id", this.customer.data.parent_id)
			if (this.customer.data.contact_name) formData.append("contact_name", this.customer.data.contact_name)

			if (this.customer.data.created_by) formData.append("created_by", this.customer.data.created_by)

			api()
				.post(
					"customer",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setCustomer', response.data.data)
						}

						this.$store.dispatch('pushNotification', {text: 'customerSavedSuccessfully', type: 'success', persist: false})
						router.push('/customer/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'customerNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'customerNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchCustomers(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.customers.sorting.order_by,
				order_direction: this.customers.sorting.order_direction,

				//FILTERS
				id: this.customers.filter.id,
				contact_name: this.customers.filter.contact_name,
				customer_id: this.customers.filter.customer_id,
				created_by: this.customers.filter.created_by
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('customers',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.$store.dispatch('setCustomers', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'customers-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchCustomersSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.customers.sorting.order_by,
				order_direction: this.customers.sorting.order_direction,

				//FILTERS
				id: this.customers.filter.id,
				contact_name: this.customers.filter.contact_name,
				created_by: this.customers.filter.created_by,
			}
			let responseType = 'json'

			api().get('customers-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setCustomersSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchCustomer(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('customer/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setCustomer', response.data.data)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/customer-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteCustomer(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"customer/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'customerDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/customers")
					} else {
						this.$store.dispatch('pushNotification', {text: 'customerNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'customerNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

		//PROJECTS
		setProjectDefaults() {
			this.$store.dispatch('setProject', _.clone(this.$store.state.defaults.project))
		},
		saveProject() {
			this.$store.dispatch('setDataSaving', true)
			let formData = new FormData()

			if (this.project.data.id) formData.append("id", this.project.data.id)

			if (this.project.data.customer_id) formData.append("customer_id", this.project.data.customer_id)
			if (this.project.data.status) formData.append("status", this.project.data.status)
			if (this.project.data.title) formData.append("title", this.project.data.title)
			if (this.project.data.description) formData.append("description", this.project.data.description)

			if (this.project.data.created_by) formData.append("created_by", this.project.data.created_by)

			api()
				.post(
					"project",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						},
						onUploadProgress: function (progressEvent) {
							this.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					this.$store.dispatch('setDataSaving', false)
					if (response.status === 200) {
						//TODO CHECK RESPONSE
						if (response.data.data) {
							this.$store.dispatch('setProject', response.data.data)
						}

						this.$store.dispatch('pushNotification', {text: 'projectSavedSuccessfully', type: 'success', persist: false})
						router.push('/project/' + response.data.data.id + '/edit')
					} else {
						this.$store.dispatch('pushNotification', {text: 'projectNotSaved', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'projectNotSaved', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataSaving', false)
				})
		},
		fetchProjects(exportToXls = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			let getParams = {
				//PAGINATION
				page: this.page,

				//SORTING
				order_by: this.projects.sorting.order_by,
				order_direction: this.projects.sorting.order_direction,

				//FILTERS
				id: this.projects.filter.id,
				customer_id: this.projects.filter.customer_id,
				status: this.projects.filter.status,
				title: this.projects.filter.title,
				created_by: this.projects.filter.created_by
			}
			let responseType = 'json'
			if (exportToXls) {
				getParams.export_to_xls = true
				responseType = 'blob'
			}

			api().get('projects',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (!exportToXls) {
					if (response.data.data) this.$store.dispatch('setProjects', response.data.data)

					//TODO: CHECK DEFAULT PAGINATION ON EMPTY PAGINATION DATA
					if (response.data.per_page) this.pagination.per_page = +response.data.per_page
					if (response.data.current_page) this.pagination.current_page = +response.data.current_page
					if (response.data.to) this.pagination.to = +response.data.to
					if (response.data.from) this.pagination.from = +response.data.from
					else this.pagination.from = 0
					if (response.data.total) this.pagination.total = +response.data.total
					else this.pagination.total = 0
					if (response.data.last_page) this.pagination.last_page = +response.data.last_page
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;

					link.setAttribute('download', 'projects-' + moment().format("DD-MM-YYYY") + '.xlsx'); //or any other extension
					document.body.appendChild(link);
					link.click();
				}

				this.$store.dispatch('setLocalDataLoading', false)

			}).catch(e => {
				console.log(e)
			}).finally(() => {
				//setTimeout(() => this.$store.dispatch('setLocalDataLoading', false), 3000)
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		fetchProjectsSelector() {
			this.$store.dispatch('setInWindowLoading', true)
			let getParams = {
				//SORTING
				order_by: this.projects.sorting.order_by,
				order_direction: this.projects.sorting.order_direction,

				//FILTERS
				id: this.projects.filter.id,
				supplier_code: this.projects.filter.supplier_code,
				title: this.projects.filter.title,
				category: this.projects.filter.category,
				created_by: this.projects.filter.created_by,
			}
			let responseType = 'json'

			api().get('projects-selector',
				{
					responseType: responseType,
					params: getParams,
					headers: {
						'Authorization': 'Bearer ' + this.$store.state.apiToken
					}
				}
			).then((response) => {
				if (response.data) this.$store.dispatch('setProjectsSelector', response.data)
				this.$store.dispatch('setInWindowLoading', false)
			}).catch(e => {
				console.log(e)
			}).finally(() => {
				this.$store.dispatch('setInWindowLoading', false)
			})
		},
		fetchProject(id = 0, silent = false) {
			this.$store.dispatch('setLocalDataLoading', true)
			api().get('project/' + id,
				{
					// params: { id: id },
					headers: {'Authorization': 'Bearer ' + this.$store.state.apiToken}
				}
			).then((response) => {
				if (response.data.data) {
					this.$store.dispatch('setProject', response.data.data)
				}
				this.$store.dispatch('setLocalDataLoading', false)
			}).catch(e => {
				//TODO: CLEAR LOGIN ON ERROR
				console.log(e)
				router.push('/project-error')
			}).finally(() => {
				this.$store.dispatch('setLocalDataLoading', false)
			})
		},
		deleteProject(id) {
			this.$store.dispatch('setDataDeleting', true)
			api()
				.delete(
					"project/" + id,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.apiToken
						}
					})
				.then((response) => {
					this.$store.dispatch('setDataDeleting', false)
					if (response.status === 200) {
						this.$store.dispatch('pushNotification', {text: 'projectDeletedSuccessfully', type: 'success', persist: false})
						this.$router.push("/projects")
					} else {
						this.$store.dispatch('pushNotification', {text: 'projectNotDeleted', type: 'error', persist: true})
					}
				})
				.catch(e => {
					if (e.response.status === 401) {
						this.$store.dispatch('setApiToken', null)
						this.$store.dispatch('setUserData', [])
					}
					this.$store.dispatch('pushNotification', {text: 'projectNotDeleted', type: 'error', persist: true})
				})
				.finally(() => {
					this.$store.dispatch('setDataDeleting', false)
				})
		},

	},

	created() {

	},
}
