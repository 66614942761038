import api from "@/api"
import {mapState} from "vuex"
import {filesize} from "filesize"

export default {
	data() {
		return {

		}
	},

	computed: {
		// _() {
		// 	return _
		// }
		...mapState([

		])
	},

	methods: {
		downloadFile(file) {
			this.$store.dispatch('setFileLoading', true)
			api().get('file/' + file.uuid, {
				headers: {
					'Authorization': 'Bearer ' + this.$store.state.apiToken
				},
				responseType: 'blob'
			}).then(response => {
				this.$store.dispatch('setFileLoading', false)
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			});
		},
		deleteFile(file) {
			console.warn('DELETE FILE '+file.uuid)
		},
		formatFileSize(size) {
			return filesize(size, {base: 2, standard: "jedec"})
		},
	},

	created() {

	},
}
