import {mapState} from "vuex";

export default {
	data() {
		return {

		}
	},

	computed: {
		// _() {
		// 	return _
		// }
		...mapState([

		])
	},

	methods: {
		itemName(item) {
			return (+item.id) ? item.contact_name : this.$t('newCustomer')
		}
	},

	created() {

	},
}
