<template>
	<div class="flex items-center bg-yellow-100 text-yellow-800 px-6 py-5 font-medium">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "Banner",
	components: {},
	props: {
		pages: Array
	},
	computed: {}
}
</script>
