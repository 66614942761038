<template>
	<div class="grow relative">
		<div v-if="!globalDataLoading">
			<div class="space-y-3">
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__id') }}
					<input v-model.trim="filter.id" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__id')" type="text"
						   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<!-- category -->
				<label class="block text-sm font-medium text-gray-700">{{ $t('form_label__category') }}
					<select v-model="filter.category" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(option, option_key) in parameters.formulations.category" :key="option" :value="option">{{ $t('formulation_category_' + option) }}</option>
					</select>
				</label>
				
				<!-- type -->
				<label class="block text-sm font-medium text-gray-700">{{ $t('form_label__type') }}
					<select v-model="filter.type" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						
						<option v-for="(option, option_key) in parameters.formulations.type" :key="option_key" :value="option_key">{{ $t('formulation_type_' + option_key) }}</option>
					
					</select>
				</label>
				
				<!-- subtype -->
				<label v-if="filter.type" class="block text-sm font-medium text-gray-700">{{ $t('form_label__subtype') }}
					<select v-model="filter.subtype" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(option, option_key) in parameters.formulations.type[filter.type]" :key="option" :value="option">{{ $t('formulation_type_' + option) }}</option>
					</select>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('form_label__SKU') }}
					<input v-model.trim="filter.SKU" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__SKU')" type="text"
						   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
				</label>
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('form_label__title') }}
					<input v-model.trim="filter.title" autocomplete="no-autocomplete" :placeholder="$t('form_placeholder__title')" type="text"
						   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm placeholder-gray-300"/>
				</label>
				
				<!-- divider -->
				<div class="relative pb-2">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="w-full border-t border-gray-200"/>
					</div>
				</div>
				
				<label class="block text-sm font-medium text-gray-700">{{ $t('formLabel__createdBy') }}
					<select v-model="filter.created_by" class="block w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
						<option :value="null">---</option>
						<option v-for="(manager, managerKey) in managers" :key="managerKey" :value="managerKey">{{ manager.given_name + ' ' + manager.family_name }}</option>
					</select>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex"
import DataLoading from "@/components/helpers/DataLoading"
import FormulationsMixin from "@/mixins/FormulationsMixin";

export default {
	components: {
		DataLoading
	},
	mixins: [FormulationsMixin],
	watch: {
		"filter.type": function (newVal, oldVal) {
			this.filter.subtype = null
		},
	},
	props: {
		filter: Object
	},
	computed: {
		...mapState(['globalDataLoading', 'localDataLoading', 'managers', 'parameters'])
	},
	methods: {},
	mounted() {
	}
}
</script>

<style scoped>
</style>
