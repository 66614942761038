<template>
	<div class="flex flex-1">
		<form class="flex w-full md:ml-0" action="#" method="GET">
			<label for="search-field" class="sr-only">{{$t('Label_Search_Field')}}</label>
			<div class="relative w-full text-gray-400 focus-within:text-gray-600">
				<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center" aria-hidden="true">
					<MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true"/>
				</div>
				<input autocomplete="no-autocomplete" @click="$store.dispatch('setGlobalSearchEnabled', true)" class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm" :placeholder="$t('Placeholder_Search_Field')" type="search"/>
			</div>
		</form>
	</div>
</template>

<script setup>
import {
	MagnifyingGlassIcon
} from '@heroicons/vue/20/solid'
</script>

<script>
import {mapState} from 'vuex'

import {
	MagnifyingGlassIcon
} from '@heroicons/vue/20/solid'

export default {
	components: {
		MagnifyingGlassIcon
	},
	data() {
		return {}
	},
	methods: {
	
	},
	computed: {
		...mapState([
			'globalSearchEnabled'
		])
	}
}

</script>
