<template>
	<div class="flex flex-shrink-0 border-t border-gray-200 p-3 bg-white">
		<a href="#" class="group block w-full flex-shrink-0">
			<div class="flex items-center">
				<div class="flex -space-x-2 overflow-hidden p-1">
					<img class="inline-block h-8 w-8 rounded-full ring-2 ring-green-500" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
					<img class="inline-block h-8 w-8 rounded-full ring-2 ring-green-500" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
					<img class="inline-block h-8 w-8 rounded-full ring-2 ring-red-500" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" alt=""/>
					<img class="inline-block h-8 w-8 rounded-full ring-2 ring-green-500" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
				</div>
			</div>
		</a>
	</div>
</template>

<script setup>
</script>
