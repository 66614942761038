import {version} from '../../package.json'
import {HomeModernIcon, BriefcaseIcon, CheckCircleIcon, InboxStackIcon, ListBulletIcon, BeakerIcon, FolderIcon, DocumentChartBarIcon, DocumentDuplicateIcon, HomeIcon, ClipboardDocumentListIcon, Cog8ToothIcon, UserGroupIcon, RectangleGroupIcon, RectangleStackIcon, Squares2X2Icon, SquaresPlusIcon} from "@heroicons/vue/24/outline";

import {createStore} from 'vuex'
import router from "@/router"
import api from "@/api"

const store = createStore({
	state() {
		return {
			AppVersion: version,

			interfaceLanguage: 'en',
			fallbackLocale: 'en',
			locales: [
				{
					name: 'English',
					code: 'en',
					iso: 'en-US'
				},
				{
					name: 'Русский',
					code: 'ru',
					iso: 'ru-RU'
				}
			],

			isDarkMode: false,

			userData: {},
			apiToken: null,

			database: null,
			databaseName: 'oem',
			// databaseObjectStoreName: 'formsData',
			// keyPath: 'formIndex',

			isOnline: navigator.onLine,

			initialLoading: false,

			globalDataLoading: true,
			localDataLoading: false,
			inWindowLoading: false,

			searchResultsLoading: false,

			fileLoading: false,

			dataLoading: false,
			dataSaving: false,
			dataDeleting: false,

			hasNewerVersion: false,

			customerBusinessTypesData: [
				'distributor',
				'retailer_department_store',
				'online_platform',
				'pharma_clinics_dermatologist',
				'beauticians',
			],
			countriesData: {
				"1": {
					"id": "1",
					"title": "Afghanistan",
					"iso": "AF"
				},
				"2": {
					"id": "2",
					"title": "Albania",
					"iso": "AL"
				},
				"3": {
					"id": "3",
					"title": "Algeria",
					"iso": "DZ"
				},
				"4": {
					"id": "4",
					"title": "Andorra",
					"iso": "AD"
				},
				"5": {
					"id": "5",
					"title": "Angola",
					"iso": "AO"
				},
				"6": {
					"id": "6",
					"title": "Anguilla",
					"iso": "AI"
				},
				"7": {
					"id": "7",
					"title": "Antigua and Barbuda",
					"iso": "AG"
				},
				"8": {
					"id": "8",
					"title": "Argentina",
					"iso": "AR"
				},
				"9": {
					"id": "9",
					"title": "Armenia",
					"iso": "AM"
				},
				"10": {
					"id": "10",
					"title": "Aruba",
					"iso": "AW"
				},
				"11": {
					"id": "11",
					"title": "Ascension Island",
					"iso": "AC"
				},
				"12": {
					"id": "12",
					"title": "Australia",
					"iso": "AU"
				},
				"13": {
					"id": "13",
					"title": "Austria",
					"iso": "AT"
				},
				"14": {
					"id": "14",
					"title": "Azerbaijan",
					"iso": "AZ"
				},
				"15": {
					"id": "15",
					"title": "Bahamas",
					"iso": "BS"
				},
				"16": {
					"id": "16",
					"title": "Bahrain",
					"iso": "BH"
				},
				"17": {
					"id": "17",
					"title": "Bangladesh",
					"iso": "BD"
				},
				"18": {
					"id": "18",
					"title": "Barbados",
					"iso": "BB"
				},
				"19": {
					"id": "19",
					"title": "Belarus",
					"iso": "BY"
				},
				"20": {
					"id": "20",
					"title": "Belgium",
					"iso": "BE"
				},
				"21": {
					"id": "21",
					"title": "Belize",
					"iso": "BZ"
				},
				"22": {
					"id": "22",
					"title": "Benin",
					"iso": "BJ"
				},
				"23": {
					"id": "23",
					"title": "Bermuda",
					"iso": "BM"
				},
				"24": {
					"id": "24",
					"title": "Bhutan",
					"iso": "BT"
				},
				"25": {
					"id": "25",
					"title": "Bolivia",
					"iso": "BO"
				},
				"26": {
					"id": "26",
					"title": "Bosnia and Herzegovina",
					"iso": "BA"
				},
				"27": {
					"id": "27",
					"title": "Botswana",
					"iso": "BW"
				},
				"28": {
					"id": "28",
					"title": "Brazil",
					"iso": "BR"
				},
				"29": {
					"id": "29",
					"title": "Brunei",
					"iso": "BN"
				},
				"30": {
					"id": "30",
					"title": "Bulgaria",
					"iso": "BG"
				},
				"31": {
					"id": "31",
					"title": "Burkina Faso",
					"iso": "BF"
				},
				"32": {
					"id": "32",
					"title": "Burundi",
					"iso": "BI"
				},
				"33": {
					"id": "33",
					"title": "Cambodia",
					"iso": "KH"
				},
				"34": {
					"id": "34",
					"title": "Cameroon",
					"iso": "CM"
				},
				"35": {
					"id": "35",
					"title": "Canada",
					"iso": "CA"
				},
				"36": {
					"id": "36",
					"title": "Cape Verde",
					"iso": "CV"
				},
				"37": {
					"id": "37",
					"title": "Cayman Islands",
					"iso": "KY"
				},
				"38": {
					"id": "38",
					"title": "Central African Republic",
					"iso": "CF"
				},
				"39": {
					"id": "39",
					"title": "Chad",
					"iso": "TD"
				},
				"40": {
					"id": "40",
					"title": "Chile",
					"iso": "CL"
				},
				"41": {
					"id": "41",
					"title": "China (People’s Republic of)",
					"iso": "CN"
				},
				"42": {
					"id": "42",
					"title": "Cocos (keeling) Islands",
					"iso": "CC"
				},
				"43": {
					"id": "43",
					"title": "Christmas Island",
					"iso": "CX"
				},
				"44": {
					"id": "44",
					"title": "Colombia",
					"iso": "CO"
				},
				"45": {
					"id": "45",
					"title": "Comoros",
					"iso": "KM"
				},
				"46": {
					"id": "46",
					"title": "Congo, Democratic Republic",
					"iso": "CD"
				},
				"47": {
					"id": "47",
					"title": "Congo, Republic",
					"iso": "CG"
				},
				"48": {
					"id": "48",
					"title": "Cook Islands",
					"iso": "CK"
				},
				"49": {
					"id": "49",
					"title": "Costa Rica",
					"iso": "CR"
				},
				"50": {
					"id": "50",
					"title": "Cote d'Ivoire",
					"iso": "CI"
				},
				"51": {
					"id": "51",
					"title": "Croatia",
					"iso": "HR"
				},
				"52": {
					"id": "52",
					"title": "Cuba",
					"iso": "CU"
				},
				"53": {
					"id": "53",
					"title": "Curacao",
					"iso": "CW"
				},
				"54": {
					"id": "54",
					"title": "Cyprus",
					"iso": "CY"
				},
				"55": {
					"id": "55",
					"title": "Czech Republic",
					"iso": "CZ"
				},
				"56": {
					"id": "56",
					"title": "Denmark",
					"iso": "DK"
				},
				"57": {
					"id": "57",
					"title": "Djibouti",
					"iso": "DJ"
				},
				"58": {
					"id": "58",
					"title": "Dominica",
					"iso": "DM"
				},
				"59": {
					"id": "59",
					"title": "Dominican Republic",
					"iso": "DO"
				},
				"60": {
					"id": "60",
					"title": "Ecuador",
					"iso": "EC"
				},
				"61": {
					"id": "61",
					"title": "Egypt",
					"iso": "EG"
				},
				"62": {
					"id": "62",
					"title": "El Salvador",
					"iso": "SV"
				},
				"63": {
					"id": "63",
					"title": "Equatorial Guinea",
					"iso": "GQ"
				},
				"64": {
					"id": "64",
					"title": "Eritrea",
					"iso": "ER"
				},
				"65": {
					"id": "65",
					"title": "Estonia",
					"iso": "EE"
				},
				"66": {
					"id": "66",
					"title": "Etiopia",
					"iso": "ET"
				},
				"67": {
					"id": "67",
					"title": "Falkland Island",
					"iso": "FK"
				},
				"68": {
					"id": "68",
					"title": "Faroe Islands",
					"iso": "FO"
				},
				"69": {
					"id": "69",
					"title": "Fiji",
					"iso": "FJ"
				},
				"70": {
					"id": "70",
					"title": "Finland",
					"iso": "FI"
				},
				"71": {
					"id": "71",
					"title": "France",
					"iso": "FR"
				},
				"72": {
					"id": "72",
					"title": "French Antarctica",
					"iso": "TF"
				},
				"73": {
					"id": "73",
					"title": "French Guiana",
					"iso": "GF"
				},
				"74": {
					"id": "74",
					"title": "French Polynesia",
					"iso": "PF"
				},
				"75": {
					"id": "75",
					"title": "Gabon",
					"iso": "GA"
				},
				"76": {
					"id": "76",
					"title": "Gambia",
					"iso": "GM"
				},
				"77": {
					"id": "77",
					"title": "Georgia, Republic",
					"iso": "GE"
				},
				"78": {
					"id": "78",
					"title": "Germany",
					"iso": "DE"
				},
				"79": {
					"id": "79",
					"title": "Ghana",
					"iso": "GH"
				},
				"80": {
					"id": "80",
					"title": "Gibraltar",
					"iso": "GI"
				},
				"81": {
					"id": "81",
					"title": "Great Britain and Northern Ireland",
					"iso": "GB"
				},
				"82": {
					"id": "82",
					"title": "Greece",
					"iso": "GR"
				},
				"83": {
					"id": "83",
					"title": "Greenland",
					"iso": "GL"
				},
				"84": {
					"id": "84",
					"title": "Grenada",
					"iso": "GD"
				},
				"85": {
					"id": "85",
					"title": "Guadaloupe",
					"iso": "GP"
				},
				"86": {
					"id": "86",
					"title": "Guam",
					"iso": "GU"
				},
				"87": {
					"id": "87",
					"title": "Guatemala",
					"iso": "GT"
				},
				"88": {
					"id": "88",
					"title": "Guernsey",
					"iso": "GG"
				},
				"89": {
					"id": "89",
					"title": "Guinea,Republic",
					"iso": "GN"
				},
				"90": {
					"id": "90",
					"title": "Guinea-Bissau",
					"iso": "GW"
				},
				"91": {
					"id": "91",
					"title": "Guyana",
					"iso": "GY"
				},
				"92": {
					"id": "92",
					"title": "Haiti",
					"iso": "HT"
				},
				"93": {
					"id": "93",
					"title": "Honduras",
					"iso": "HN"
				},
				"94": {
					"id": "94",
					"title": "Hong Kong",
					"iso": "HK"
				},
				"95": {
					"id": "95",
					"title": "Hungary",
					"iso": "HU"
				},
				"96": {
					"id": "96",
					"title": "Iceland",
					"iso": "IS"
				},
				"97": {
					"id": "97",
					"title": "India",
					"iso": "IN"
				},
				"98": {
					"id": "98",
					"title": "Indonesia",
					"iso": "ID"
				},
				"99": {
					"id": "99",
					"title": "Iran",
					"iso": "IR"
				},
				"100": {
					"id": "100",
					"title": "Iraq",
					"iso": "IQ"
				},
				"101": {
					"id": "101",
					"title": "Ireland",
					"iso": "IE"
				},
				"102": {
					"id": "102",
					"title": "Isle of Man",
					"iso": "IM"
				},
				"103": {
					"id": "103",
					"title": "Israel",
					"iso": "IL"
				},
				"104": {
					"id": "104",
					"title": "Italy",
					"iso": "IT"
				},
				"105": {
					"id": "105",
					"title": "Jamaica",
					"iso": "JM"
				},
				"106": {
					"id": "106",
					"title": "Jersey",
					"iso": "JE"
				},
				"107": {
					"id": "107",
					"title": "Japan",
					"iso": "JP"
				},
				"108": {
					"id": "108",
					"title": "Jordan",
					"iso": "JO"
				},
				"109": {
					"id": "109",
					"title": "Kazakstan",
					"iso": "KZ"
				},
				"110": {
					"id": "110",
					"title": "Kenya",
					"iso": "KE"
				},
				"111": {
					"id": "111",
					"title": "Kiribati",
					"iso": "KI"
				},
				"112": {
					"id": "112",
					"title": "Korea, Dem. People’s Republic of",
					"iso": "KP"
				},
				"113": {
					"id": "113",
					"title": "South Korea",
					"iso": "KR"
				},
				"114": {
					"id": "114",
					"title": "Kosovo (UN Interim Administration)",
					"iso": "XK"
				},
				"115": {
					"id": "115",
					"title": "Kuwait",
					"iso": "KW"
				},
				"116": {
					"id": "116",
					"title": "Kyzgyzstan",
					"iso": "KG"
				},
				"117": {
					"id": "117",
					"title": "Laos",
					"iso": "LA"
				},
				"118": {
					"id": "118",
					"title": "Latvia",
					"iso": "LV"
				},
				"119": {
					"id": "119",
					"title": "Lebanon",
					"iso": "LB"
				},
				"120": {
					"id": "120",
					"title": "Lesotho",
					"iso": "LS"
				},
				"121": {
					"id": "121",
					"title": "Liberia",
					"iso": "LR"
				},
				"122": {
					"id": "122",
					"title": "Libyan",
					"iso": "LY"
				},
				"123": {
					"id": "123",
					"title": "Lithuania",
					"iso": "LT"
				},
				"124": {
					"id": "124",
					"title": "Luxembourg",
					"iso": "LU"
				},
				"125": {
					"id": "125",
					"title": "Macao",
					"iso": "MO"
				},
				"126": {
					"id": "126",
					"title": "Macedonia",
					"iso": "MK"
				},
				"127": {
					"id": "127",
					"title": "Madagascar",
					"iso": "MG"
				},
				"128": {
					"id": "128",
					"title": "Malawi",
					"iso": "MW"
				},
				"129": {
					"id": "129",
					"title": "Malaysia",
					"iso": "MY"
				},
				"130": {
					"id": "130",
					"title": "Maldives",
					"iso": "MV"
				},
				"131": {
					"id": "131",
					"title": "Mali",
					"iso": "ML"
				},
				"132": {
					"id": "132",
					"title": "Malta",
					"iso": "MT"
				},
				"133": {
					"id": "133",
					"title": "Mariana Islands",
					"iso": "MP"
				},
				"134": {
					"id": "134",
					"title": "Marshall Islands",
					"iso": "MH"
				},
				"135": {
					"id": "135",
					"title": "Martinique",
					"iso": "MQ"
				},
				"136": {
					"id": "136",
					"title": "Mauritania",
					"iso": "MR"
				},
				"137": {
					"id": "137",
					"title": "Mauritius",
					"iso": "MU"
				},
				"138": {
					"id": "138",
					"title": "Mayotte",
					"iso": "YT"
				},
				"139": {
					"id": "139",
					"title": "Mexico",
					"iso": "MX"
				},
				"140": {
					"id": "140",
					"title": "Micronesia (Federated States of)",
					"iso": "FM"
				},
				"141": {
					"id": "141",
					"title": "Moldava",
					"iso": "MD"
				},
				"142": {
					"id": "142",
					"title": "Monaco",
					"iso": "MC"
				},
				"143": {
					"id": "143",
					"title": "Mongolia",
					"iso": "MN"
				},
				"144": {
					"id": "144",
					"title": "Montenegro, Republic",
					"iso": "ME"
				},
				"145": {
					"id": "145",
					"title": "Montserrat",
					"iso": "MS"
				},
				"146": {
					"id": "146",
					"title": "Morocco",
					"iso": "MA"
				},
				"147": {
					"id": "147",
					"title": "Mozambique",
					"iso": "MZ"
				},
				"148": {
					"id": "148",
					"title": "Myanmar (Union)",
					"iso": "MM"
				},
				"149": {
					"id": "149",
					"title": "Namibia",
					"iso": "NA"
				},
				"150": {
					"id": "150",
					"title": "Nauru",
					"iso": "NR"
				},
				"151": {
					"id": "151",
					"title": "Nepal",
					"iso": "NP"
				},
				"152": {
					"id": "152",
					"title": "Netherlands",
					"iso": "NL"
				},
				"153": {
					"id": "153",
					"title": "New Caledonia",
					"iso": "NC"
				},
				"154": {
					"id": "154",
					"title": "New Zealand",
					"iso": "NZ"
				},
				"155": {
					"id": "155",
					"title": "Nicaragua",
					"iso": "NI"
				},
				"156": {
					"id": "156",
					"title": "Niger",
					"iso": "NE"
				},
				"157": {
					"id": "157",
					"title": "Nigeria",
					"iso": "NG"
				},
				"158": {
					"id": "158",
					"title": "Norfolk Island",
					"iso": "NF"
				},
				"159": {
					"id": "159",
					"title": "Norway",
					"iso": "NO"
				},
				"160": {
					"id": "160",
					"title": "Oman",
					"iso": "OM"
				},
				"161": {
					"id": "161",
					"title": "Pakistan",
					"iso": "PK"
				},
				"162": {
					"id": "162",
					"title": "Palau",
					"iso": "PW"
				},
				"163": {
					"id": "163",
					"title": "Palestine",
					"iso": "PS"
				},
				"164": {
					"id": "164",
					"title": "Panama",
					"iso": "PA"
				},
				"165": {
					"id": "165",
					"title": "Papua New Guinea",
					"iso": "PG"
				},
				"166": {
					"id": "166",
					"title": "Paraguay",
					"iso": "PY"
				},
				"167": {
					"id": "167",
					"title": "Peru",
					"iso": "PE"
				},
				"168": {
					"id": "168",
					"title": "Philippines",
					"iso": "PH"
				},
				"169": {
					"id": "169",
					"title": "Pitcairn",
					"iso": "PN"
				},
				"170": {
					"id": "170",
					"title": "Poland",
					"iso": "PL"
				},
				"171": {
					"id": "171",
					"title": "Portugal",
					"iso": "PT"
				},
				"172": {
					"id": "172",
					"title": "Puerto Rico",
					"iso": "PR"
				},
				"173": {
					"id": "173",
					"title": "Qatar",
					"iso": "QA"
				},
				"174": {
					"id": "174",
					"title": "Reunion",
					"iso": "RE"
				},
				"175": {
					"id": "175",
					"title": "Romania",
					"iso": "RO"
				},
				"176": {
					"id": "176",
					"title": "Russian Federation",
					"iso": "RU"
				},
				"177": {
					"id": "177",
					"title": "Rwanda",
					"iso": "RW"
				},
				"178": {
					"id": "178",
					"title": "Saint Barthelemy",
					"iso": "BL"
				},
				"179": {
					"id": "179",
					"title": "Saint Christopher and Nevis",
					"iso": "KN"
				},
				"180": {
					"id": "180",
					"title": "Saint Helena",
					"iso": "SH"
				},
				"181": {
					"id": "181",
					"title": "Saint Lucia",
					"iso": "LC"
				},
				"182": {
					"id": "182",
					"title": "Saint Maarten",
					"iso": "SX"
				},
				"183": {
					"id": "183",
					"title": "Saint Martin",
					"iso": "MF"
				},
				"184": {
					"id": "184",
					"title": "Saint-Pierre and Miquelon",
					"iso": "PM"
				},
				"185": {
					"id": "185",
					"title": "Saint Vincent and the Grenadines",
					"iso": "VC"
				},
				"186": {
					"id": "186",
					"title": "Samoa, American",
					"iso": "AS"
				},
				"187": {
					"id": "187",
					"title": "Samoa, West",
					"iso": "WS"
				},
				"188": {
					"id": "188",
					"title": "San Marino",
					"iso": "SM"
				},
				"189": {
					"id": "189",
					"title": "Sao Tome and Principe",
					"iso": "ST"
				},
				"190": {
					"id": "190",
					"title": "Saudi Arabia",
					"iso": "SA"
				},
				"191": {
					"id": "191",
					"title": "Senegal",
					"iso": "SN"
				},
				"192": {
					"id": "192",
					"title": "Serbia Republic",
					"iso": "RS"
				},
				"193": {
					"id": "193",
					"title": "Seychelles",
					"iso": "SC"
				},
				"194": {
					"id": "194",
					"title": "Sierra Leone",
					"iso": "SL"
				},
				"195": {
					"id": "195",
					"title": "Singapore",
					"iso": "SG"
				},
				"196": {
					"id": "196",
					"title": "Slovakia, Republic of",
					"iso": "SK"
				},
				"197": {
					"id": "197",
					"title": "Slovenia",
					"iso": "SI"
				},
				"198": {
					"id": "198",
					"title": "Solomon Islands",
					"iso": "SB"
				},
				"199": {
					"id": "199",
					"title": "Somalia",
					"iso": "SO"
				},
				"200": {
					"id": "200",
					"title": "South Africa",
					"iso": "ZA"
				},
				"201": {
					"id": "201",
					"title": "South Georgia and the south Sandw. Isl.",
					"iso": "GS"
				},
				"202": {
					"id": "202",
					"title": "South Sudan",
					"iso": "SS"
				},
				"203": {
					"id": "203",
					"title": "Spain",
					"iso": "ES"
				},
				"204": {
					"id": "204",
					"title": "Sri Lanka",
					"iso": "LK"
				},
				"205": {
					"id": "205",
					"title": "Sudan",
					"iso": "SD"
				},
				"206": {
					"id": "206",
					"title": "Suriname",
					"iso": "SR"
				},
				"207": {
					"id": "207",
					"title": "Swaziland",
					"iso": "SZ"
				},
				"208": {
					"id": "208",
					"title": "Sweden",
					"iso": "SE"
				},
				"209": {
					"id": "209",
					"title": "Syria",
					"iso": "SY"
				},
				"210": {
					"id": "210",
					"title": "Taiwan (Chinese Taipei)",
					"iso": "TW"
				},
				"211": {
					"id": "211",
					"title": "Tajikistan",
					"iso": "TJ"
				},
				"212": {
					"id": "212",
					"title": "Tanzania",
					"iso": "TZ"
				},
				"213": {
					"id": "213",
					"title": "Thailand",
					"iso": "TH"
				},
				"214": {
					"id": "214",
					"title": "Timor-Leste",
					"iso": "TL"
				},
				"215": {
					"id": "215",
					"title": "Togo",
					"iso": "TG"
				},
				"216": {
					"id": "216",
					"title": "Tokelau Islands",
					"iso": "TK"
				},
				"217": {
					"id": "217",
					"title": "Tonga",
					"iso": "TO"
				},
				"218": {
					"id": "218",
					"title": "Trinidad and Tobago",
					"iso": "TT"
				},
				"219": {
					"id": "219",
					"title": "Tristan da Cunha",
					"iso": "TA"
				},
				"220": {
					"id": "220",
					"title": "Tunisia",
					"iso": "TN"
				},
				"221": {
					"id": "221",
					"title": "Turkey",
					"iso": "TR"
				},
				"222": {
					"id": "222",
					"title": "Turkmenistan",
					"iso": "TM"
				},
				"223": {
					"id": "223",
					"title": "Turks and Caicos",
					"iso": "TC"
				},
				"224": {
					"id": "224",
					"title": "Tuvalu",
					"iso": "TV"
				},
				"225": {
					"id": "225",
					"title": "Uganda",
					"iso": "UG"
				},
				"226": {
					"id": "226",
					"title": "Ukraine",
					"iso": "UA"
				},
				"227": {
					"id": "227",
					"title": "United Arab Emirates",
					"iso": "AE"
				},
				"228": {
					"id": "228",
					"title": "United States of America",
					"iso": "US"
				},
				"229": {
					"id": "229",
					"title": "United States Minor Outlying Islands",
					"iso": "UM"
				},
				"230": {
					"id": "230",
					"title": "Uruguay",
					"iso": "UY"
				},
				"231": {
					"id": "231",
					"title": "Uzbekistan",
					"iso": "UZ"
				},
				"232": {
					"id": "232",
					"title": "Vanuatu",
					"iso": "VU"
				},
				"233": {
					"id": "233",
					"title": "Vatican",
					"iso": "VA"
				},
				"234": {
					"id": "234",
					"title": "Venezuela",
					"iso": "VE"
				},
				"235": {
					"id": "235",
					"title": "Viet Nam",
					"iso": "VN"
				},
				"236": {
					"id": "236",
					"title": "Virgin Islands, british (Tortola)",
					"iso": "VG"
				},
				"237": {
					"id": "237",
					"title": "Virgin Islands (USA)",
					"iso": "VI"
				},
				"238": {
					"id": "238",
					"title": "Wallis and Futuna Islands",
					"iso": "WF"
				},
				"239": {
					"id": "239",
					"title": "Yemen",
					"iso": "YE"
				},
				"240": {
					"id": "240",
					"title": "Zambie",
					"iso": "ZM"
				},
				"241": {
					"id": "241",
					"title": "Zimbabwe",
					"iso": "ZW"
				},
				"242": {
					"id": "242",
					"title": "Switzerland",
					"iso": "CH"
				}
			},

			exhibitionDefault: 'cosmoprof_asia_hong_kong_2024',
			exhibitionList: {},

			isPersisted: false,
			dataUsage: {},

			uploadProgress: 0,

			notifications: [],

			navigationPrimary: [
				{name: 'dashboard', href: '/', icon: HomeIcon, disabled: false, count: 0},
				{name: 'leads', href: '/leads', icon: ClipboardDocumentListIcon, disabled: false, count: 0},
				{name: 'customers', href: '/customers', icon: UserGroupIcon, disabled: true, count: 0},
				{name: 'projects', href: '/projects', icon: InboxStackIcon, disabled: true, count: 0},
				{name: 'components', href: '/components', icon: SquaresPlusIcon, disabled: true, count: 0},
				{name: 'products', href: '/products', icon: Squares2X2Icon, disabled: true, count: 0},
				{name: 'formulations', href: '/formulations', icon: BeakerIcon, disabled: true, count: 0},
				{name: 'production', href: '/production', icon: RectangleStackIcon, disabled: true, count: 0},
				{name: 'warehouse', href: '/warehouse', icon: HomeModernIcon, disabled: true, count: 0},
			],
			navigationSecondary: [
				{name: 'tasks', href: '/tasks', icon: CheckCircleIcon, disabled: true, count: 0},
				{name: 'invoices', href: '/invoices', icon: DocumentDuplicateIcon, disabled: true, count: 0},
				{name: 'counterparties', href: '/counterparties', icon: BriefcaseIcon, disabled: true, count: 0},
				{name: 'services', href: '/services', icon: ListBulletIcon, disabled: true, count: 0},
				{name: 'files', href: '/files', icon: FolderIcon, disabled: true, count: 0},
				{name: 'reports', href: '/reports', icon: DocumentChartBarIcon, disabled: true, count: 0},
				{name: 'settings', href: '/settings', icon: Cog8ToothIcon, disabled: true, count: 0}
			],

			priorityLevels: {
				0: {key: 'none', color: 'gray'},
				1: {key: 'lowest', color: 'slate'},
				2: {key: 'low', color: 'cyan'},
				3: {key: 'normal', color: 'green'},
				4: {key: 'medium', color: 'yellow'},
				5: {key: 'high', color: 'orange'},
				6: {key: 'highest', color: 'red'},
			},

			defaults: {
				lead: {
					id: 0,

					priority: 0,

					form_index: null,

					exhibition: null,

					interest_evenswiss: 0,
					interest_ucls: 0,
					interest_alove: 0,
					interest_suissette: 0,

					company_name: null,

					given_name: null,
					family_name: null,

					email: null,
					country_id: 0,
					country_name: null,

					website: null,
					notes: null,

					countries_selected: [], //STRING PRESENTED

					customer_business_types: [], //STRING PRESENTED
					other_type_of_business: null,

					created_by: 0,

					created_at: null,
					updated_at: null,

					synchronized_at: null,

					follow_up: 0,
					follow_up_at: null,

					data_checked: 0,

					archived_at: null,
				},

				component: {
					id: 0,
					category: null,

					supplier_code: null,

					title: null,
					description: null,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				product: {
					id: 0,
					parent_id: 0,

					SKU: null,
					title: null,
					variant_title: null,

					description: null,

					customer_id: 0,
					project_id: 0,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null,
					components: []
				},

				service: {
					id: 0,

					category: null,

					title: null,
					description: null,

					price: 0,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				counterparty: {
					id: 0,

					title: null,
					description: null,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				formulation: {
					id: 0,
					parent_id: 0,
					SKU: null,

					type: null,
					subtype: null,

					title: null,

					category: null,

					description: null,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				customer: {
					id: 0,
					parent_id: 0,

					contact_name: null,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				project: {
					id: 0,

					customer_id: 0,
					status: 0,
					title: null,
					description: null,

					created_by: 0,
					created_at: null,
					updated_at: null,

					deleted_at: null
				},

				task: {
					id: 0,
					title: null,
					description: null,
					required: null,
					priority: 0,
					status: 0,
					completed: 0,
					parent_id: 0,
					lead_id: 0,
					customer_id: 0,
					project_id: 0,
					process_id: 0,
					sort_order: 0,
					created_at: null,
					updated_at: null,
					deleted_at: null
				}
			},

			slideOverStatus: false,
			slideOverContentType: null,
			slideOverDimming: true,
			slideOverDirection: 'right',
			slideOverContent: {},


			modalWindowStatus: false,
			modalWindowType: null,
			modalWindowContent: {
				heading: 'Heading',
				description: 'Description',
				button_confirm: 'Confirm',
				button_cancel: 'Cancel'
			},

			managers: [],
			counters: [],

			parameters: {},

			globalSearchEnabled:false,
			searchResults: [],

			component: {
				data: {},
				logs: [],
			},
			components: {
				api_route: 'components',

				data: [],
				filter: {
					id: null,
					category: null,
					supplier_code: null,
					title: null,
					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'title',
					order_direction: 'ASC'
				}
			},

			product: {
				data: {},
				logs: [],
			},
			products: {
				api_route: 'products',

				data: [],
				filter: {
					id: null,
					title: null,
					SKU: null,

					customer_id: null,

					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'title',
					order_direction: 'ASC'
				}
			},

			formulation: {
				data: {},
				logs: [],
			},
			formulations: {
				api_route: 'formulations',

				data: [],
				filter: {
					id: null,
					SKU: null,

					category: null,
					type: null,
					subtype: null,

					title: null,

					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'SKU',
					order_direction: 'ASC'
				}
			},

			service: {
				data: {},
				logs: [],
			},
			services: {
				api_route: 'services',

				data: [],
				filter: {
					id: null,

					category: null,
					title: null,

					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'category',
					order_direction: 'DESC'
				}
			},

			counterparty: {
				data: {},
				logs: [],
			},
			counterparties: {
				api_route: 'counterparties',

				data: [],
				filter: {
					id: null,
					title: null,
					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'title',
					order_direction: 'ASC'
				}
			},

			customer: {
				data: {},
				logs: [],
			},
			customers: {
				api_route: 'customers',

				data: [],
				filter: {
					id: null,
					contact_name: null,

					parent_id: null,

					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'contact_name',
					order_direction: 'ASC'
				}
			},

			project: {
				data: {},
				logs: [],
			},
			projects: {
				api_route: 'projects',

				data: [],
				filter: {
					id: null,

					customer_id: null,
					status: null,
					title: null,

					created_by: null,
					created_at: null
				},
				sorting: {
					order_by: 'title',
					order_direction: 'ASC'
				}
			},
		}
	},
	mutations: {
		updateDatabase(state, database) {
			state.database = database
		},
		updateOnlineStatus(state, isOnline) {
			state.isOnline = isOnline
		},

		setDataLoading(state, loading) {
			state.dataLoading = loading
		},
		setInitialLoading(state, loading) {
			console.log('initialLoading: '+loading)
			state.initialLoading = loading
		},
		setGlobalDataLoading(state, loading) {
			console.log('globalDataLoading: '+ loading)
			state.globalDataLoading = loading
		},
		setLocalDataLoading(state, loading) {
			state.localDataLoading = loading
		},
		setInWindowLoading(state, loading) {
			state.inWindowLoading = loading
		},
		setSearchResultsLoading(state, loading) {
			state.searchResultsLoading = loading
		},
		setFileLoading(state, loading) {
			state.fileLoading = loading
		},

		setGlobalSearchEnabled(state, value) {
			state.globalSearchEnabled = value
		},
		setSearchResults(state, value) {
			state.searchResults = value
		},


		setDataSaving(state, saving) {
			state.dataSaving = saving
		},

		setDataDeleting(state, deleting) {
			state.dataDeleting = deleting
		},

		toggleDarkMode(state, darkMode) {
			state.isDarkMode = +darkMode
			localStorage.isDarkMode = +darkMode
			if (+darkMode) {
				document.body.classList.add("dark")
				document.querySelector('meta[name="theme-color"]').setAttribute('content', '#101729')
			} else {
				document.body.classList.remove("dark")
				document.querySelector('meta[name="theme-color"]').setAttribute('content', '#F8F9FA')
			}
		},
		updateHasNewerVersion(state, value) {
			state.hasNewerVersion = value
		},
		setApiToken(state, apiToken) {
			state.apiToken = apiToken
			if (apiToken) {
				localStorage.apiToken = apiToken
			} else {
				localStorage.removeItem('apiToken')
				state.userData = {}
			}
		},
		setUserData(state, userData) {
			state.userData = userData
		},

		triggerSlideOver(state, value) {
			state.slideOverStatus = value
			// if (!value) {
			// 	setTimeout(() => {
			// 		state.slideOverContentType = null
			// 		state.slideOverContent = {}
			// 	}, 1000)
			// }
		},
		setSlideOverContent(state, {type, content, dimming, direction, updated}) {
			state.slideOverContentType = type
			state.slideOverDimming = dimming
			state.slideOverContent = content
			state.slideOverDirection = direction
			// console.log('updated: ' + updated)
		},

		setModalWindowContent(state, {type, content}) {
			state.modalWindowType = type
			state.modalWindowContent = content
		},


		triggerModalWindow(state, value) {
			state.modalWindowStatus = value
			// if (!value) {
			// 	setTimeout(() => {
			// 		state.slideOverContentType = null
			// 		state.slideOverContent = {}
			// 	}, 1000)
			// }
		},

		setParameters(state, parameters) {
			state.parameters = parameters
		},

		setCounters(state, counters) {
			state.counters = counters
		},

		setLeadsCounter(state, counter) {
			state.counters.leads = +counter
		},

		setManagers(state, managers) {
			state.managers = managers
		},

		setExhibitions(state, exhibitions) {
			state.exhibitionList = exhibitions
		},

		setComponent(state, data) {
			state.component.data = data
		},
		setComponentLogs(state, data) {
			state.component.logs = data
		},
		setComponents(state, data) {
			state.components.data = data
		},
		setComponentsSelector(state, data) {
			state.components.data = data
		},

		setFormulation(state, data) {
			state.formulation.data = data
		},
		setFormulationLogs(state, data) {
			state.formulation.logs = data
		},
		setFormulations(state, data) {
			state.formulations.data = data
		},
		setFormulationsSelector(state, data) {
			state.formulations.data = data
		},

		setProduct(state, data) {
			state.product.data = data
		},
		setProductLogs(state, data) {
			state.product.logs = data
		},
		setProducts(state, data) {
			state.products.data = data
		},
		setProductsSelector(state, data) {
			state.products.data = data
		},

		setService(state, data) {
			state.service.data = data
		},
		setServiceLogs(state, data) {
			state.service.logs = data
		},
		setServices(state, data) {
			state.services.data = data
		},
		setServicesSelector(state, data) {
			state.services.data = data
		},

		setCounterparty(state, data) {
			state.counterparty.data = data
		},
		setCounterpartyLogs(state, data) {
			state.counterparty.logs = data
		},
		setCounterparties(state, data) {
			state.counterparties.data = data
		},
		setCounterpartiesSelector(state, data) {
			state.counterparties.data = data
		},

		setCustomer(state, data) {
			state.customer.data = data
		},
		setCustomerLogs(state, data) {
			state.customer.logs = data
		},
		setCustomers(state, data) {
			state.customers.data = data
		},
		setCustomersSelector(state, data) {
			state.customers.data = data
		},

		setProject(state, data) {
			state.project.data = data
		},
		setProjectLogs(state, data) {
			state.project.logs = data
		},
		setProjects(state, data) {
			state.projects.data = data
		},
		setProjectsSelector(state, data) {
			state.projects.data = data
		},
	},
	actions: {
		init({dispatch}) {
			console.log('INIT')
			// dispatch('initDatabase').then(() => {
			// 	dispatch('getStagedFormsData')
			// }).catch((e) => {
			// 	console.error(e)
			// })
		},

		setApiToken({commit}, apiToken) {
			commit('setApiToken', apiToken);
		},
		setUserData({commit}, userData) {
			commit('setUserData', userData);
		},

		setDataLoading({commit, state}, loading) {
			commit('setDataLoading', loading)
		},
		setInitialLoading({commit, state}, loading) {
			commit('setInitialLoading', loading)
		},
		setGlobalDataLoading({commit, state}, loading) {
			commit('setGlobalDataLoading', loading)
		},
		setLocalDataLoading({commit, state}, loading) {
			commit('setLocalDataLoading', loading)
		},
		setInWindowLoading({commit, state}, loading) {
			commit('setInWindowLoading', loading)
		},
		setSearchResultsLoading({commit, state}, loading) {
			commit('setSearchResultsLoading', loading)
		},
		setFileLoading({commit, state}, loading) {
			commit('setFileLoading', loading)
		},
		setDataSaving({commit}, saving) {
			commit('setDataSaving', saving)
		},
		setDataDeleting({commit}, deleting) {
			commit('setDataDeleting', deleting)
		},

		setGlobalSearchEnabled({commit}, value) {
			commit('setGlobalSearchEnabled', value)
		},
		setSearchResults({commit, state}, data) {
			commit('setSearchResults', data)
		},

		syncData({dispatch, state}, {silent, redirect}) {
			console.log('syncData')
			//this.fetchInitialData()
		},

		pushNotification({dispatch, state}, {text, type, persist}) {
			dispatch('addNotification', {text: text, type: type, persist: persist})
		},
		addNotification({dispatch, state}, {text, type, persist}) {
			let notification = {type: type, persist: persist, header: type, message: text}
			state.notifications.push(notification)
			if (!persist) setTimeout(() => dispatch('removeNotification', +state.notifications.length - 1), 3000)
		},
		removeNotification({state}, notificationIndex) {
			state.notifications.splice(notificationIndex, 1)
		},

		checkDataUsage({commit}) {
			navigator.storage.estimate().then((estimate) => {
				commit('updateDataUsage', estimate)
			})
		},
		toggleDarkMode({commit}, darkMode) {
			commit('toggleDarkMode', darkMode)
		},

		triggerSlideOver({commit}, open) {
			commit('triggerSlideOver', open)
		},

		setSlideOverContent({commit}, {type, content, dimming, direction, updated}) {
			// console.log(type)
			// console.log(slideContent)
			commit('setSlideOverContent', {type: type, content: content, dimming:dimming, direction:direction, updated: updated})
		},

		triggerModalWindow({commit}, open) {
			commit('triggerModalWindow', open)
		},
		setModalWindowContent({commit}, {type, content, event_type}) {
			commit('setModalWindowContent', {type: type, content: content})
		},

		async setParameters({commit, state}, parameters) {
			commit('setParameters', parameters)
		},
		setCounters({commit, state}, counters) {
			commit('setCounters', counters)
		},
		setLeadsCounter({commit, state}, counter) {
			commit('setLeadsCounter', counter)
		},
		setManagers({commit, state}, managers) {
			commit('setManagers', managers)
		},

		setExhibitions({commit, state}, exhibitions) {
			commit('setExhibitions', exhibitions)
		},





		setComponent({commit, state}, data) {
			commit('setComponent', data)
		},
		setComponentLogs({commit, state}, data) {
			commit('setComponentLogs', data)
		},
		setComponents({commit, state}, data) {
			commit('setComponents', data)
		},
		setComponentsSelector({commit, state}, data) {
			commit('setComponentsSelector', data)
		},

		setFormulation({commit, state}, data) {
			commit('setFormulation', data)
		},
		setFormulationLogs({commit, state}, data) {
			commit('setFormulationLogs', data)
		},
		setFormulations({commit, state}, data) {
			commit('setFormulations', data)
		},
		setFormulationsSelector({commit, state}, data) {
			commit('setFormulationsSelector', data)
		},

		setProduct({commit, state}, data) {
			commit('setProduct', data)
		},
		setProductLogs({commit, state}, data) {
			commit('setProductLogs', data)
		},
		setProducts({commit, state}, data) {
			commit('setProducts', data)
		},
		setProductsSelector({commit, state}, data) {
			commit('setProductsSelector', data)
		},

		setService({commit, state}, data) {
			commit('setService', data)
		},
		setServiceLogs({commit, state}, data) {
			commit('setServiceLogs', data)
		},
		setServices({commit, state}, data) {
			commit('setServices', data)
		},
		setServicesSelector({commit, state}, data) {
			commit('setServicesSelector', data)
		},

		setCounterparty({commit, state}, data) {
			commit('setCounterparty', data)
		},
		setCounterpartyLogs({commit, state}, data) {
			commit('setCounterpartyLogs', data)
		},
		setCounterparties({commit, state}, data) {
			commit('setCounterparties', data)
		},
		setCounterpartiesSelector({commit, state}, data) {
			commit('setCounterpartiesSelector', data)
		},

		setCustomer({commit, state}, data) {
			commit('setCustomer', data)
		},
		setCustomerLogs({commit, state}, data) {
			commit('setCustomerLogs', data)
		},
		setCustomers({commit, state}, data) {
			commit('setCustomers', data)
		},
		setCustomersSelector({commit, state}, data) {
			commit('setCustomersSelector', data)
		},

		setProject({commit, state}, data) {
			commit('setProject', data)
		},
		setProjectLogs({commit, state}, data) {
			commit('setProjectLogs', data)
		},
		setProjects({commit, state}, data) {
			commit('setProjects', data)
		},
		setProjectsSelector({commit, state}, data) {
			commit('setProjectsSelector', data)
		},
	}
})
export default store
