import {createRouter, createWebHistory} from 'vue-router'

const routes = [
	{path: '/', name: 'dashboard', component: () => /* webpackChunkName: "dashboard" */ import('@/views/Dashboard')},

	//LEADS
	{path: "/lead", props: false, name: "leads-new", component: () => import(/* webpackChunkName: "leads" */ "@/views/leads/Edit")},
	{path: "/lead/:id", props: true, name: "leads-view", component: () => import(/* webpackChunkName: "leads" */ "@/views/leads/View")},
	{path: "/lead/:id/edit", props: true, name: "leads-edit", component: () => import(/* webpackChunkName: "leads" */ "@/views/leads/Edit")},
	{path: "/lead-error", props: false, name: "lead-error", component: () => import(/* webpackChunkName: "leads" */ "@/views/leads/Error")},
	{path: '/leads', name: 'leads', component: () => /* webpackChunkName: "leads" */ import('@/views/leads/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "leads-paginated",
				component: () =>
					import(/* webpackChunkName: "leads" */ "@/views/leads/List.vue")
			}
		]
	},


	{path: "/component", props: false, name: "components-new", component: () => import(/* webpackChunkName: "component" */ "@/views/components/Edit")},
	{path: "/component/:id", props: true, name: "components-view", component: () => import(/* webpackChunkName: "component" */ "@/views/components/View")},
	{path: "/component/:id/edit", props: true, name: "components-edit", component: () => import(/* webpackChunkName: "component" */ "@/views/components/Edit")},
	{path: "/component-error", props: false, name: "component-error", component: () => import(/* webpackChunkName: "component" */ "@/views/components/Error")},
	{path: '/components', name: 'components', component: () => /* webpackChunkName: "component" */ import('@/views/components/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "components-paginated",
				component: () =>
					import(/* webpackChunkName: "component" */ "@/views/components/List")
			}
		]
	},

	{path: "/formulation", props: false, name: "formulations-new", component: () => import(/* webpackChunkName: "formulation" */ "@/views/formulations/Edit")},
	{path: "/formulation/:id", props: true, name: "formulations-view", component: () => import(/* webpackChunkName: "formulation" */ "@/views/formulations/View")},
	{path: "/formulation/:id/edit", props: true, name: "formulations-edit", component: () => import(/* webpackChunkName: "formulation" */ "@/views/formulations/Edit")},
	{path: "/formulation-error", props: false, name: "formulation-error", component: () => import(/* webpackChunkName: "formulation" */ "@/views/formulations/Error")},
	{path: '/formulations', name: 'formulations', component: () => /* webpackChunkName: "formulation" */ import('@/views/formulations/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "formulations-paginated",
				component: () =>
					import(/* webpackChunkName: "formulation" */ "@/views/formulations/List")
			}
		]
	},

	{path: "/product", props: false, name: "products-new", component: () => import(/* webpackChunkName: "product" */ "@/views/products/Edit")},
	{path: "/product/:id", props: true, name: "products-view", component: () => import(/* webpackChunkName: "product" */ "@/views/products/View")},
	{path: "/product/:id/edit", props: true, name: "products-edit", component: () => import(/* webpackChunkName: "product" */ "@/views/products/Edit")},
	{path: "/product-error", props: false, name: "product-error", component: () => import(/* webpackChunkName: "product" */ "@/views/products/Error")},
	{path: '/products', name: 'products', component: () => /* webpackChunkName: "product" */ import('@/views/products/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "products-paginated",
				component: () =>
					import(/* webpackChunkName: "product" */ "@/views/products/List")
			}
		]
	},

	{path: "/service", props: false, name: "services-new", component: () => import(/* webpackChunkName: "service" */ "@/views/services/Edit")},
	{path: "/service/:id", props: true, name: "services-view", component: () => import(/* webpackChunkName: "service" */ "@/views/services/View")},
	{path: "/service/:id/edit", props: true, name: "services-edit", component: () => import(/* webpackChunkName: "service" */ "@/views/services/Edit")},
	{path: "/service-error", props: false, name: "service-error", component: () => import(/* webpackChunkName: "service" */ "@/views/services/Error")},
	{path: '/services', name: 'services', component: () => /* webpackChunkName: "service" */ import('@/views/services/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "services-paginated",
				component: () =>
					import(/* webpackChunkName: "service" */ "@/views/services/List")
			}
		]
	},

	{path: "/counterparty", props: false, name: "counterparties-new", component: () => import(/* webpackChunkName: "counterparty" */ "@/views/counterparties/Edit")},
	{path: "/counterparty/:id", props: true, name: "counterparties-view", component: () => import(/* webpackChunkName: "counterparty" */ "@/views/counterparties/View")},
	{path: "/counterparty/:id/edit", props: true, name: "counterparties-edit", component: () => import(/* webpackChunkName: "counterparty" */ "@/views/counterparties/Edit")},
	{path: "/counterparty-error", props: false, name: "component-error", component: () => import(/* webpackChunkName: "counterparty" */ "@/views/counterparties/Error")},
	{path: '/counterparties', name: 'counterparties', component: () => /* webpackChunkName: "counterparty" */ import('@/views/counterparties/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "counterparties-paginated",
				component: () =>
					import(/* webpackChunkName: "counterparty" */ "@/views/counterparties/List")
			}
		]
	},


	{path: "/customer", props: false, name: "customers-new", component: () => import(/* webpackChunkName: "customer" */ "@/views/customers/Edit")},
	{path: "/customer/:id", props: true, name: "customers-view", component: () => import(/* webpackChunkName: "customer" */ "@/views/customers/View")},
	{path: "/customer/:id/edit", props: true, name: "customers-edit", component: () => import(/* webpackChunkName: "customer" */ "@/views/customers/Edit")},
	{path: "/customer-error", props: false, name: "customer-error", component: () => import(/* webpackChunkName: "customer" */ "@/views/customers/Error")},
	{path: '/customers', name: 'customers', component: () => /* webpackChunkName: "customer" */ import('@/views/customers/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "customers-paginated",
				component: () =>
					import(/* webpackChunkName: "customer" */ "@/views/customers/List")
			}
		]
	},


	{path: "/project", props: false, name: "projects-new", component: () => import(/* webpackChunkName: "project" */ "@/views/projects/Edit")},
	{path: "/project/:id", props: true, name: "projects-view", component: () => import(/* webpackChunkName: "project" */ "@/views/projects/View")},
	{path: "/project/:id/edit", props: true, name: "projects-edit", component: () => import(/* webpackChunkName: "project" */ "@/views/projects/Edit")},
	{path: "/project-error", props: false, name: "project-error", component: () => import(/* webpackChunkName: "project" */ "@/views/projects/Error")},
	{path: '/projects', name: 'projects', component: () => /* webpackChunkName: "project" */ import('@/views/projects/List.vue'), props: false,
		children: [
			{
				path: ":page",
				props: true,
				name: "projects-paginated",
				component: () =>
					import(/* webpackChunkName: "project" */ "@/views/projects/List")
			}
		]
	},


	{path: '/production', name: 'production', component: () => /* webpackChunkName: "production" */ import('@/views/Production')},

	{path: '/invoices', name: 'invoices', component: () => /* webpackChunkName: "invoices" */ import('@/views/InvoicesList')},
	{path: '/files', name: 'files', component: () => /* webpackChunkName: "files" */ import('@/views/Files')},
	{path: '/reports', name: 'reports', component: () => /* webpackChunkName: "reports" */ import('@/views/Reports')},
	{path: '/settings', name: 'settings', component: () => /* webpackChunkName: "settings" */ import('@/views/Settings')},

	//TASKS
	// {path: "/task", props: false, name: "tasks-new", component: () => import(/* webpackChunkName: "tasks" */ "@/views/tasks/TaskEdit")},
	// {path: "/task/:id", props: true, name: "tasks-view", component: () => import(/* webpackChunkName: "tasks" */ "@/views/tasks/TaskView")},
	// {path: "/task/:id/edit", props: true, name: "tasks-edit", component: () => import(/* webpackChunkName: "tasks" */ "@/views/tasks/TaskEdit")},
	// {path: "/task-error", props: false, name: "lead-error", component: () => import(/* webpackChunkName: "tasks" */ "@/views/tasks/TaskError")},
	// {path: '/tasks', name: 'tasks', component: () => /* webpackChunkName: "tasks" */ import('@/views/tasks/TasksList'), props: false},

	{path: '/algorithms', name: 'algorithms', component: () => /* webpackChunkName: "algorithms" */ import('@/views/algorithms/AlgorithmConstructor.vue')},

	{path: '/:catchAll(.*)', name: "bad_routing", props: false, component: () => import(/* webpackChunkName: "errors" */ "@/views/errors/BadRouting")}
]

const router = createRouter({
	history: createWebHistory('/'),
	base: '/',
	linkExactActiveClass: "is-active",
	routes,
})

export default router
